import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVICE_TAG_TYPES } from "./Constants";
import {
  InteractionRequiredAuthError,
  BrowserAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { amiAdminApiTokenRequest, msalConfig } from "authConfig";
import { redirect } from "react-router-dom";
import { removeCookie, getCookie, setCookie } from "Cookies/CookieUtils";

export const baseAPI = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: async (headers, { getState }) => {
      let token = "";
      const msalInstance = new PublicClientApplication(msalConfig);
      try {
        
        var isUserLoggedOut = localStorage.getItem("isUserLoggedOut");
        
        // If the user is already logged out, redirect to the login page
        if (isUserLoggedOut === "true") {
          msalInstance.logoutRedirect({
            onRedirectNavigate: (url) => {
              return false;
            },
          });
          document.location.href = "/";
        } else {

          var currentUserId = getCookie("currentuserid");
          var authenticationResult = await msalInstance.acquireTokenSilent(
            amiAdminApiTokenRequest
          );

          // This logic is added to make sure that the user is logged out if the account is changed.
          if (
            authenticationResult.account &&
            currentUserId &&
            authenticationResult.account.localAccountId !== currentUserId
          ) {
            localStorage.setItem("isUserLoggedOut", "true");
            msalInstance.logoutRedirect({
              onRedirectNavigate: (url) => {
                return false;
              },
            });
            document.location.href = "/";
          }

          if (
            currentUserId === null &&
            authenticationResult.account?.localAccountId
          ) {
            setCookie(
              "currentuserid",
              authenticationResult.account?.localAccountId,
              1
            );
          }

          token = authenticationResult.accessToken;

          console.log("acquired token: ", token);
        }
      } catch (error) {
        console.log("BaseAPI Error: ", error);
        if (error instanceof InteractionRequiredAuthError) {
          try {
            msalInstance.acquireTokenRedirect(amiAdminApiTokenRequest);
          } catch (error) {
            console.log("BaseAPI acquireTokenRedirect error: ", error);
          }
        } else if (error instanceof BrowserAuthError) {
          msalInstance
            .handleRedirectPromise()
            .then(async (tokenResponse) => {
              if (!tokenResponse) {
                const accounts = msalInstance.getAllAccounts();
                if (accounts.length === 0) {
                  await msalInstance.loginRedirect();
                }
              }
            })
            .catch((err) => {
              console.log("Redirect promise error:", err);
            });
        } else {
          console.log("silent token acquisition error:", error);
        }
      }
      headers.set("authorization", `Bearer ${token}`);
      headers.set(
        "Strict-Transport-Security",
        "max-age=3600 ; includeSubDomains"
      );
      headers.set(
        "Ocp-Apim-Subscription-Key",
        process.env.REACT_APP_SUBSCRIPTION_KEY ?? ""
      );

      headers.set("Accept", "*/*");
      headers.set("Accept-Encoding", "gzip, deflate, br");
      headers.set("Connection", "keep-alive");
      return headers;
    },
  }),
  tagTypes: Object.values(SERVICE_TAG_TYPES),
  endpoints: () => ({}),
  keepUnusedDataFor: 1,
});
