import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FileState } from "BugTracker/Common/Constants/FileUpload";
import { IFileState } from "BugTracker/Common/Interfaces";

const IInitialFileState: IFileState[] = [];

export const getFileDisplayValue = (fileSizeInBytes: number): string => {
  const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const exponent = Math.min(Math.floor(Math.log(fileSizeInBytes) / Math.log(1024)), units.length - 1);
  const approx = fileSizeInBytes / 1024 ** exponent;
  const output =
    exponent === 0 ? `${fileSizeInBytes} bytes` : `${approx.toFixed(3)} ${units[exponent]} (${fileSizeInBytes} bytes)`;
  return output;
};

export const generateFileNumber = (state: IFileState[], fileNumberRange = 1000): number => {
  let fileNumber = Math.floor(Math.random() * fileNumberRange);
  let isFileNumberDuplicated = state.length === 0 ? false : state.some((fileData) => fileData.fileNumber === fileNumber);
  while (isFileNumberDuplicated) {
    fileNumber = Math.floor(Math.random() * fileNumberRange);
    for (let index = 0; index < state.length; index++) {
      isFileNumberDuplicated = state[index].fileNumber === fileNumber;
      if (isFileNumberDuplicated) {
        break;
      }
    }
  }
  return fileNumber;
};

const fileSlice = createSlice({
  name: "bugTrackerFile",
  initialState: IInitialFileState,
  reducers: {
    addFile: (state, { payload }: PayloadAction<{ file: File; formData: FormData; controller: AbortController }>) => {
      const { file, formData, controller } = payload;
      return [
        ...state,
        {
          file: file,
          controller,
          formData,
          fileNumber: generateFileNumber(state),
          progress: 0,
          fileState: FileState.ReadyToUpload,
          fileName: file.name,
          fileType: file.type,
          fileSizeInBytes: file.size,
          fileSizeDisplayValue: getFileDisplayValue(file.size),
        },
      ];
    },
    addUploadReadyFile: (state, { payload }: PayloadAction<{ fileData: IFileState }>) => {
      return [...state, payload.fileData];
    },
    removeFile: (state, { payload: fileNumber }: PayloadAction<number>) =>
      state.filter((fileDetail) => fileDetail.fileNumber !== fileNumber),
    cancelUploadSingleFile: (state, { payload: fileNumber }: PayloadAction<number>) =>
      void state.map((fileDetail) => {
        if (fileDetail.fileNumber === fileNumber) {
          fileDetail.controller.abort();
          fileDetail.fileState = FileState.Cancelled;
        }
        return fileDetail;
      }),
    cancelAllFileUploads: (state, { payload }: PayloadAction) =>
      void state.map((fileDetail) => {
        if (![FileState.Cancelled, FileState.UploadCompleted, FileState.ReadyToUpload].includes(fileDetail.fileState)) {
          fileDetail.controller.abort();
          fileDetail.fileState = FileState.Cancelled;
        }
        return [];
      }),
    setProgress: (state, { payload: fileProgress }: PayloadAction<{ progress: number; fileNumber: number }>) =>
      void state.map((fileDetail) => {
        if (fileDetail.fileNumber === fileProgress.fileNumber) {
          fileDetail.progress = fileProgress.progress;
          fileDetail.fileState = fileProgress.progress === 100 ? FileState.Processing : FileState.Uploading;
        }
        return fileDetail;
      }),
    setFileState: (state, { payload }: PayloadAction<{ fileNumber: number; fileState: FileState; bugId?: number }>) =>
      void state.map((fileItem) => {
        if (fileItem.fileNumber === payload.fileNumber) {
          fileItem.fileState = payload.fileState;
          fileItem.bugId = payload?.bugId;
        }
        return fileItem;
      }),
    updateFileDetails: (
      state,
      { payload }: PayloadAction<{ fileNumber: number; newFileName: string; newDescription?: string; newFormData: FormData }>
    ) =>
      void state.map((fileItem) => {
        if (fileItem.fileNumber === payload.fileNumber) {
          fileItem.fileName = payload.newFileName ? payload.newFileName : fileItem.fileName;
          fileItem.newFileDescription = payload.newDescription ? payload.newDescription : fileItem.newFileDescription;
          fileItem.formData = payload.newFormData ? payload.newFormData : fileItem.formData;
        }
        return fileItem;
      }),

    resetFileUploads: (state, { payload }: PayloadAction) => [],
  },
});

export const {
  addFile,
  removeFile,
  cancelUploadSingleFile,
  setProgress,
  setFileState,
  addUploadReadyFile,
  cancelAllFileUploads,
  resetFileUploads,
  updateFileDetails,
} = fileSlice.actions;

export default fileSlice.reducer;
