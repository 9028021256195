import { Tooltip } from "@mui/material";
import { $ListItemText, $Stack } from "Common/Components";

interface IDetailItem {
  primary: string;
  secondary: string;
}

export const DetailItem: React.FC<IDetailItem> = (props) => {
  const { primary, secondary } = props;
  return (
    <$Stack>
      <span>
        <Tooltip title={secondary} arrow>
          <$ListItemText
            primary={primary}
            secondary={secondary}
            secondaryTypographyProps={{
              noWrap: true,
            }}
          />
        </Tooltip>
      </span>{" "}
    </$Stack>
  );
};
