import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React from "react";

interface ISnackbar {
  open: boolean;
  onClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
  isSuccess: boolean;
  message: string;
}

const $Snackbar: React.FC<ISnackbar> = (props) => {
  const { open, onClose, isSuccess, message } = props;
  return (
    <>
      {open && (
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={onClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={onClose}
            severity={isSuccess ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default $Snackbar;
