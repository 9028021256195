import { $Avatar, $Badge, $Stack, $Typography,} from "Common/Components";
import { useTheme } from "@mui/material/styles";
import { CompanyOutlined } from "Icons/Icons";

export interface IEntityAvatar {
    name: string;
    onClick?: any;
    width?: string;
    height?: string;
    isActivate?: boolean;

}

export const EntityAvatar: React.FC<IEntityAvatar> = (props) => {
    const theme = useTheme();
    const { onClick, name, width = 42, height = 42, isActivate= true } = props;

    return (
        <>

            <$Stack spacing={2} direction="row" alignItems="center"
                data-testid="provider-avatar"
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                    onClick(e)
                }
            >
                <$Badge color="error" variant="dot" invisible={isActivate}>
                    <$Avatar
                        sx={{
                            width: { width },
                            height: { height },
                            bgcolor: "#fff",
                            color: theme.palette.grey[800],
                            borderRadius: "3px",
                            border: `1px solid ${theme.palette.grey[400]}`,

                        }}
                        variant="rounded"

                    >
                        <CompanyOutlined />
                    </$Avatar>
                </$Badge>
                <$Stack>
                    <$Typography variant="h5" color={theme.palette.primary.main}>
                        {name}
                    </$Typography>

                </$Stack>
            </$Stack>

        </>
    );
};
