import { FileState } from "BugTracker/Common/Constants";
import { DialogType } from "BugTracker/Common/Constants/DialogType";
import { checkIfAllowedFileType } from "BugTracker/Common/Functions";
import { IBug } from "BugTracker/Common/Interfaces";
import { resetFileUploads } from "BugTracker/Store/FileSlice";
import { uploadFileStraight } from "BugTracker/Store/Services/Thunks/FileUpload";
import { ISnackbarState } from "Common/Interfaces";
import { RootState, useAppDispatch, useAppSelector } from "Store";
import { ChangeEvent, useEffect, useState } from "react";

interface IUseFileUpload {
  isAllowedDragAndDrop: boolean | undefined;
  fileChangeEventHandler: (files: File[]) => void;
  onSubmitSnackbar: (state: ISnackbarState) => void;
  drawerCloseEvent: () => void;
  allowedFileTypes?: string[];
}

export const UseFileUpload = ({
  isAllowedDragAndDrop,
  fileChangeEventHandler,
  onSubmitSnackbar,
  drawerCloseEvent,
  allowedFileTypes,
}: IUseFileUpload) => {
  const dispatch = useAppDispatch();
  const [viewUpload, setViewUpload] = useState<DialogType | null>(null);
  const [isNotAllowed, setIsNotAllowed] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [restrictedFiles, setRestrictedFiles] = useState<File[]>([]);
  const [errorTexts, setErrorTexts] = useState<string[]>([]);

  const [hasUploadProcessStarted, setHasUploadProcessStarted] = useState<boolean>(false);

  const getFileState = (state: RootState) => state.bugTrackerFile;
  const stateFiles = useAppSelector((store) => getFileState(store));

  useEffect(() => {
    if (stateFiles.length > 0 && stateFiles.some((file) => file.fileState === FileState.Uploading)) {
      setHasUploadProcessStarted(true);
    }
    if (stateFiles.length > 0 && stateFiles.every((file) => file.fileState === FileState.UploadCompleted)) {
      setHasUploadProcessStarted(false);
      onSubmitSnackbar &&
        onSubmitSnackbar({
          message: `Bug (Id: ${
            stateFiles.find((file) => file.fileState === FileState.UploadCompleted)?.bugId
          }) reported successfully.`,
          isSuccess: true,
          isOpen: true,
        });
      setSelectedFiles([]);
      dispatch(resetFileUploads());
      drawerCloseEvent();
    }
    if (
      stateFiles.length > 0 &&
      stateFiles.some((file) => file.fileState === FileState.UploadFailed || file.fileState === FileState.Cancelled)
    ) {
      setHasUploadProcessStarted(false);
      onSubmitSnackbar &&
        onSubmitSnackbar({
          message: "Bug reported failed.",
          isSuccess: false,
          isOpen: true,
        });
    }
  }, [stateFiles]);

  const handleFileChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    let files: File[] = [];

    if (event.target.files) {
      for (let i = 0; i < event.target.files.length; i++) {
        files.push(event.target.files[i]);
      }
      fileChangeEventHandler(files);
    }
  };

  const onUploadReplaceEventHandler = () => {
    setViewUpload(DialogType.FileUpload);
  };

  const onDragEnterEventHandler = (event: React.DragEvent<HTMLDivElement> | undefined) => {
    event?.preventDefault();
    if (isAllowedDragAndDrop) {
      setViewUpload(DialogType.DragAndDropArea);
    }
  };

  const onDragLeaveEventHandler = (event: React.DragEvent<HTMLDivElement> | undefined) => {
    setViewUpload(null);
    setIsNotAllowed(false);
  };

  const onDragOverEventHandler = (event: React.DragEvent<HTMLDivElement> | undefined) => {
    if (event?.dataTransfer.items) {
      const isNotAllowed = [...event.dataTransfer.items].some((item, i) => {
        return item.type === "";
      });
      setIsNotAllowed(isNotAllowed);
    }
  };

  /** for file drop */
  const onDropEventHandler = async (event: React.DragEvent<HTMLDivElement> | undefined, allowedFileTypes: string[]) => {
    let droppedFiles: File[] = [];
    let restrictedFiles: File[] = [];
    let restrictedFileNames: File[] = [];

    if (event) {
      if (event.dataTransfer.items) {
        if (selectedFiles.length + event.dataTransfer.items.length > 5) {
          setViewUpload(DialogType.ExcessLimit);
          return;
        }
        // Use DataTransferItemList interface to access the file(s)
        [...event.dataTransfer.items].forEach((item) => {
          // If dropped items aren't files, reject them
          if (item.kind === "file") {
            const file = item.getAsFile();
            console.log(file);
            if (file) {
              let fileNameManipulated = file.name.split(".").slice(0, -1).join(".");

              if (item.type === "" || !checkIfAllowedFileType(file, allowedFileTypes ?? [])) {
                restrictedFiles.push(file);
              } else {
                droppedFiles.push(file);
                setErrorTexts([]);
              }
            }
          }
        });
      } else {
        if (selectedFiles.length + event.dataTransfer.files.length > 5) {
          setViewUpload(DialogType.ExcessLimit);
          return;
        }
        // Use DataTransfer interface to access the file(s)
        [...event.dataTransfer.files].forEach((file) => {
          console.log(file);
          let fileNameManipulated = file.name.split(".").slice(0, -1).join(".");

          if (!checkIfAllowedFileType(file, allowedFileTypes ?? [])) {
            restrictedFiles.push(file);
          } else {
            droppedFiles.push(file);
            setErrorTexts([]);
          }
        });
      }
    }

    if (restrictedFiles.length > 0 || restrictedFileNames.length > 0) {
      setRestrictedFiles(restrictedFiles);
      setViewUpload(DialogType.InvalidFile);
    } else if (droppedFiles.length > 0) {
      if (droppedFiles.length > 1) {
        console.log("dispatching files");
      }
    }
    setSelectedFiles((prev) => {
      const combinedFiles = [...prev, ...droppedFiles];
      const uniqueFileMap = combinedFiles.reduce((map, file) => {
        map.set(file.name, file);
        return map;
      }, new Map<string, File>());
      const uniqueFileArray = Array.from(uniqueFileMap.values());
      return uniqueFileArray;
    });
  };

  const clearFileUploadProcess = () => {
    setSelectedFiles([]);
    setRestrictedFiles([]);
    dispatch(resetFileUploads());
  };

  const submitBug = (values: IBug) => {
    try {
      if (selectedFiles.length < 1) {
        setErrorTexts(["At least one attachment is required."]);
        return;
      }
      dispatch(uploadFileStraight(values, selectedFiles));
    } catch (error) {
      console.error("Error creating bug:", error);
    }
  };

  const onFileChangeEventHandler = (event: ChangeEvent<HTMLInputElement>) => {
    let droppedFiles: File[] = [];
    let restrictedFiles: File[] = [];
    let restrictedFileNames: File[] = [];
    let sizeRestrictedFiles: File[] = [];

    if (event.target.files) {
      if (selectedFiles.length + event.target.files.length > 5) {
        setViewUpload(DialogType.ExcessLimit);
        return;
      }
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        let fileNameManipulated = file.name.split(".").slice(0, -1).join(".");

        if (!checkIfAllowedFileType(file, allowedFileTypes ?? [])) {
          restrictedFiles.push(file);
        } else {
          droppedFiles.push(file);
          setErrorTexts([]);
        }
      }
    }
    if (restrictedFiles.length > 0 || restrictedFileNames.length > 0) {
      setRestrictedFiles(restrictedFiles);
      setViewUpload(DialogType.InvalidFile);
    } else if (droppedFiles.length > 0) {
      if (droppedFiles.length > 1) {
        console.log("dispatching files");
      }
    }
    setSelectedFiles((prev) => {
      const combinedFiles = [...prev, ...droppedFiles];
      const uniqueFileMap = combinedFiles.reduce((map, file) => {
        map.set(file.name, file);
        return map;
      }, new Map<string, File>());
      const uniqueFileArray = Array.from(uniqueFileMap.values());
      return uniqueFileArray;
    });
    return {
      droppedFiles,
      restrictedFiles,
      restrictedFileNames,
      sizeRestrictedFiles,
    };
  };

  const onConfirmNotification = () => {
    setViewUpload(null);
  };

  return {
    viewUpload,
    isNotAllowed,
    selectedFiles,
    stateFiles,
    setViewUpload,
    setIsNotAllowed,
    setSelectedFiles,
    restrictedFiles,
    setRestrictedFiles,
    onDragEnterEventHandler,
    onDragLeaveEventHandler,
    onDropEventHandler,
    onDragOverEventHandler,
    handleFileChangeEvent,
    onUploadReplaceEventHandler,
    hasUploadProcessStarted,
    setHasUploadProcessStarted,
    errorTexts,
    setErrorTexts,
    clearFileUploadProcess,
    submitBug,
    onFileChangeEventHandler,
    onConfirmNotification,
  };
};
