import { DocFilled, FolderOutlined, ImageFilled, VideoFilled } from "Icons/Icons";
import { FILE_EXTENSIONS } from "../Constants";

export const getFileIconByExtension = (extension: FILE_EXTENSIONS | string | null) => {
  switch (extension?.toLowerCase()) {
    case FILE_EXTENSIONS.PDF:
      return "PDF";
    case FILE_EXTENSIONS.JPG:
    case FILE_EXTENSIONS.JPEG:
    case FILE_EXTENSIONS.PNG:
      return <ImageFilled />;
    case FILE_EXTENSIONS.MP4:
      return <VideoFilled />;
    case "folder":
      return <FolderOutlined />;
    default:
      return <DocFilled />;
  }
};
