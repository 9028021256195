import { useTheme } from '@mui/material/styles';

const UserInactiveImage = () => {
    const theme = useTheme();

    return (

        <>

            <svg width="141.25" height="103.65" viewBox="0 0 141.25 103.65" fill="none" xmlns="http://www.w3.org/2000/svg">

                <linearGradient id="linear-gradient" x1="70.62" y1="103.65" x2="70.62" y2="33.03" gradientUnits="userSpaceOnUse">
                    <stop offset=".15" stopColor={theme.palette.grey[100]} stopOpacity=".3" />
                    <stop offset=".38" stopColor="#f3f8fc" stopOpacity=".3" />
                    <stop offset=".59" stopColor="#cee4f3" stopOpacity=".3" />
                    <stop offset=".79" stopColor="#90c4e5" stopOpacity=".3" />
                    <stop offset=".99" stopColor="#3b96d1" stopOpacity=".3" />
                    <stop offset="1" stopColor="#3593d0" stopOpacity=".3" />
                </linearGradient>

                <path fill="url(#linear-gradient)" d="m0,103.65C0,64.65,31.62,33.03,70.62,33.03s70.62,31.62,70.62,70.62H0" />
                <circle fill={theme.palette.common.white} cx="70.56" cy="24.23" r="19.4" />
                <path fill={theme.palette.primary.main} d="m103.44,76.69v9.97h-8.09v-9.97c0-9.35-7.62-16.97-16.97-16.97h-15.63c-9.35,0-16.97,7.62-16.97,16.97v9.97h-8.09v-9.97c0-5.6,1.84-10.94,5.31-15.38,4.8-6.14,12.02-9.68,19.75-9.68h15.63c4.87,0,9.6,1.44,13.68,4.12,7.11,4.66,11.37,12.49,11.37,20.94ZM46.35,24.23c0-13.36,10.87-24.23,24.23-24.23s24.23,10.83,24.23,24.19-10.87,24.23-24.23,24.23v.04c-13.36,0-24.23-10.87-24.23-24.23Zm39.3,5.58h-30.15c2.28,6.12,8.17,10.49,15.07,10.49s12.79-4.37,15.07-10.49Zm-29.4-12.94h28.64c-2.67-5.2-8.08-8.77-14.32-8.77s-11.65,3.57-14.32,8.77Z" />
                <path fill={theme.palette.common.white} d="m54.06,18.86c-2.47,0-4.47,2.01-4.47,4.47s2.01,4.47,4.47,4.47h32.99c2.47,0,4.47-2.01,4.47-4.47s-2.01-4.47-4.47-4.47h-32.99Z" />
                <path fill={theme.palette.primary.main} d="m82.65,24.77c.2.2.2.51,0,.71-.1.1-.23.15-.35.15s-.26-.05-.35-.15l-1.43-1.43-1.43,1.43c-.1.1-.23.15-.35.15s-.26-.05-.35-.15c-.2-.2-.2-.51,0-.71l1.43-1.43-1.43-1.43c-.2-.2-.2-.51,0-.71s.51-.2.71,0l1.43,1.43,1.43-1.43c.2-.2.51-.2.71,0s.2.51,0,.71l-1.43,1.43,1.43,1.43Zm-20.55-1.43l1.43-1.43c.2-.2.2-.51,0-.71s-.51-.2-.71,0l-1.43,1.43-1.43-1.43c-.2-.2-.51-.2-.71,0s-.2.51,0,.71l1.43,1.43-1.43,1.43c-.2.2-.2.51,0,.71.1.1.23.15.35.15s.26-.05.35-.15l1.43-1.43,1.43,1.43c.1.1.23.15.35.15s.26-.05.35-.15c.2-.2.2-.51,0-.71l-1.43-1.43Z" />


            </svg>


        </>
    );
};

export default UserInactiveImage;
