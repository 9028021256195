import React, { forwardRef } from "react";
import { Button } from "@mui/material";

interface IButtonProps {
  children?: React.ReactNode;
  id?: string;
  disabled?: boolean;
  onClick: any;
  size?: "small" | "medium" | "large";
  variant?: "contained" | "outlined" | "text";
  [key: string | number | symbol]: unknown;
}

const $Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const { onClick, variant, children, ...rest } = props;
  return (
    <Button
      ref={ref}
      size="small"
      disableElevation
      children={children}
      variant={variant ?? "contained"}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        onClick(e);
      }}
      {...rest}
    />
  );
});

export default $Button;
