import { ActivateOutlined, BinOutlined, RestoreOutlined, UserResetOutlined } from "Icons/Icons";
import { $Button, $Stack, $Typography } from "./MUI";
import { CONFIRMATION_DIALOG_TYPES, DialogEvent } from "Common/Constants";
import React from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { $Tooltip } from "Common/Components";

export interface IDeleteConfirmation {
  isOpen: boolean;
  dialogEvent: (event: DialogEvent, reason: string, isActive?: number) => void;
  isSubmitting: boolean;
  dialogTitle: string | JSX.Element;
  dialogBody: string | JSX.Element;
  confirmButtonText?: string;
  cancelButtonText?: string;
  action?: CONFIRMATION_DIALOG_TYPES;
  isActive?: number;
  tooltipTitle?: {
    cancelBtn?: string;
    actionBtn?: string;
  };
}

export const DeleteConfirmation: React.FC<IDeleteConfirmation> = (props) => {
  const {
    isOpen,
    dialogEvent,
    isSubmitting,
    confirmButtonText,
    cancelButtonText,
    action = CONFIRMATION_DIALOG_TYPES.DELETE,
    isActive,
    tooltipTitle,

    dialogTitle,
    dialogBody,
  } = props;

  const generateIcon = () => {
    switch (action) {
      case CONFIRMATION_DIALOG_TYPES.ACTIVATE:
        return <ActivateOutlined color="success" sx={{ fontSize: "2.2rem" }} />;
      case CONFIRMATION_DIALOG_TYPES.RESET:
        return <UserResetOutlined color="primary" sx={{ fontSize: "2.2rem" }} />;
      case CONFIRMATION_DIALOG_TYPES.RESTORE:
        return <RestoreOutlined color="primary" sx={{ fontSize: "2.2rem" }} />;
      case CONFIRMATION_DIALOG_TYPES.DELETE:
        return <BinOutlined color="error" sx={{ fontSize: "2.2rem" }} />;
      default:
        return null;
    }
  };

  return (
    <Dialog open={isOpen} onClose={(e, reason) => dialogEvent(DialogEvent.Cancel, reason)} maxWidth="xs" fullWidth>
      <DialogTitle>
        {typeof dialogTitle === "string" ? (
          <>
            <$Stack alignItems="center" justifyContent="center" pt={2} spacing={1}>
              <>{generateIcon()}</>
              <$Typography color="primary.main" fontSize={"1.20rem"} fontWeight="fontWeightBold">
                {dialogTitle}
              </$Typography>
            </$Stack>
          </>
        ) : (
          dialogTitle
        )}
      </DialogTitle>
      <DialogContent sx={{ px: 4 }}>
        {dialogBody && (typeof dialogBody === "string" ? <DialogContentText>{dialogBody}</DialogContentText> : dialogBody)}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", py: 3 }}>
        <$Tooltip title={tooltipTitle?.cancelBtn}>
          <$Button
            variant="text"
            color="primary"
            size="small"
            onClick={() => dialogEvent(DialogEvent.Cancel, "cancelButtonClick")}
            sx={{ px: 4 }}
          >
            {cancelButtonText ? cancelButtonText : "Cancel"}
          </$Button>
        </$Tooltip>
        <$Tooltip title={tooltipTitle?.actionBtn}>
          <LoadingButton
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              dialogEvent(DialogEvent.Confirm, "deleteButtonClick", isActive);
            }}
            loading={isSubmitting}
            loadingPosition={isSubmitting ? "start" : undefined}
            startIcon={isSubmitting ? <Box sx={{ px: 1 }}> </Box> : undefined}
            sx={{ px: 4 }}
          >
            <span>{confirmButtonText ? confirmButtonText : "Delete"}</span>
          </LoadingButton>
        </$Tooltip>
      </DialogActions>
    </Dialog>
  );
};
