import { Theme } from "@mui/material";
import { blue } from "@mui/material/colors";
import { FILE_EXTENSIONS } from "../Constants";

export const getFileIconColor = (extension: FILE_EXTENSIONS | string | null, theme: Theme) => {
  switch (extension?.toLowerCase()) {
    case FILE_EXTENSIONS.PDF:
      return theme.colors.secondary.main;
    case FILE_EXTENSIONS.JPG:
    case FILE_EXTENSIONS.JPEG:
    case FILE_EXTENSIONS.PNG:
      return blue[400];
    case "folder":
      return theme.colors.primary.main;
    default:
      return theme.colors.alpha.black[30];
  }
};
