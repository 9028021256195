import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

// project import
import Logo from "./Logo";
import { config } from "Common/Constants";

// ==============================|| MAIN LOGO ||============================== //

interface ILogoSection {
  sx?: object;
  to?: string;
}

const LogoSection: React.FC<ILogoSection> = ({ sx, to }) => (
  <ButtonBase disableRipple component={Link} to={!to ? config.defaultPath : to} sx={sx}>
    <Logo />
  </ButtonBase>
);

export default LogoSection;
