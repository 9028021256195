import { MainLayout, MessageLayout } from "Layout";
import "./App.scss";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { FEATURE_ROUTES } from "Router";
import { PageNotFound, UnderConstruction, UserInactiveComponent, AppLoading } from "Common/Components";
import { useIsAuthenticated } from "@azure/msal-react";
import { useGetAuthorizationQuery } from "Store/Services/Authorization";
import React, { useEffect } from "react";
import signalRService from "SignalR/signalRInstance";

function App() {
  useEffect(() => {
    let isConnectionEstablished = false;

    signalRService.startConnection().then(() => {
      isConnectionEstablished = true;
    });

    return () => {
      if (isConnectionEstablished) {
        signalRService.stopConnection();
      }
    };
  }, []);

  const isAuthenticated = useIsAuthenticated();

  const {
    data: authData,
    isLoading: authDataLoading,
    isSuccess,
    error,
    isError,
  } = useGetAuthorizationQuery({}, { skip: !isAuthenticated });

  const ROUTES = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: FEATURE_ROUTES.map(({ path, element, children, featureFlag, errorElement }) => ({
        path,
        element: <>{element}</>,
        children,
        errorElement,
        loader: () => {
          return "";
        },
      })),
    },
    {
      path: "*",
      element: (
        <MessageLayout>
          <PageNotFound headerMessage={"Page not found."} detailMessage="" goToHomeButton />
        </MessageLayout>
      ),
      children: [],
    },
    {
      path: "/under-construction",
      element: (
        <MessageLayout>
          <UnderConstruction headerMessage={"Page is under construction."} detailMessage="" goToHomeButton />
        </MessageLayout>
      ),
      children: [],
    },
  ]);

  if (isError) {
    return (
      <MessageLayout>
        <UserInactiveComponent
          headerMessage={(error as any)?.data?.detail ?? "Unauthorized"}
          detailMessage={
            (error as any)?.data?.detail === "Your account is currently inactive."
              ? "Please contact your DSM."
              : "Please contact admin."
          }
          hideButton = {false}
        />
      </MessageLayout>
    );
  } else if (authDataLoading || !authData) {
    return <AppLoading />;
  } else if (!authDataLoading && isSuccess) {
    return <RouterProvider router={ROUTES} />;
  } else {
    return (
      <MessageLayout>
        <UserInactiveComponent
          headerMessage={"Application is not responding!"}
          detailMessage={"Please contact admin."}
          hideButton
        />
      </MessageLayout>
    );
  }
}

export default App;
