import React from "react";
import { Skeleton, SxProps, Theme } from "@mui/material";

interface ISkeletonProps {
  children?: React.ReactNode | string;
  height?: number | string;
  width?: number | string;
  animation?: "pulse" | "wave" | false;
  variant?: "circular" | "rectangular" | "rounded" | "text";
  sx?: SxProps<Theme> | undefined;
  [key: string | number | symbol]: unknown;
}

const $Skeleton: React.FC<ISkeletonProps> = (props) => {
  const { children, sx, variant, ...rest } = props;
  return <Skeleton children={children} sx={sx} {...rest} />;
};

export default $Skeleton;
