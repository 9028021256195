import { ISnackbarState } from "Common/Interfaces";
import { useState } from "react";
import { IDrawerState } from "../Interfaces";

export const useMyProfile = () => {
  const [drawer, setDrawer] = useState<IDrawerState>({
    isOpen: false,
    title: "",
    type: null,
  });

  const [snackBar, setSnackBar] = useState<ISnackbarState>({
    isOpen: false,
    isSuccess: false,
    message: "",
  });

  const handleEditProfileButton = () => {
    setDrawer({ isOpen: true, title: "Edit My Profile", type: "EDIT" });
  };

  return {
    drawer,
    snackBar,
    setDrawer,
    setSnackBar,
    handleEditProfileButton,
  };
};
