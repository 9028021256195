import { IMyDetailsEditRequest } from "Common/Interfaces";
import { IFormValues } from "../Interfaces";

export function Edit(this: IFormValues): IMyDetailsEditRequest {
  return {
    firstName: this.firstName?.trim(),
    lastName: this.lastName?.trim(),
    mobile: this.mobilePhone?.trim(),
    work: this.workPhone?.trim(),
    jobTitle: this.jobTitle?.trim(),
  };
}
