export const stringAvatar = (name: string) => {
  const splittedName = name.trim().replace(/\s+/g, " ").split(" ");
  try {
    if (splittedName.length > 0 && splittedName[0]) {
      return {
        children:
          splittedName.length > 1
            ? `${splittedName[0][0]?.toUpperCase() ?? ""}${splittedName[1][0]?.toUpperCase() ?? ""}`
            : `${splittedName[0][0]?.toUpperCase() ?? ""}${splittedName[0][1]?.toUpperCase() ?? ""}`,
      };
    }

    return null;
  } catch (error) {
    console.log(name);
    console.error(error);
    return null;
  }
};
