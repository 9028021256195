import React, { ChangeEvent, useEffect, useState } from "react";
import {
  $Autocomplete,
  $Avatar,
  $Button,
  $Grid,
  $IconButton,
  $List,
  $ListItem,
  $Select,
  $Stack,
  $TextField,
  $Tooltip,
  $Typography,
  ConfirmationDialog,
  FormContent,
  FormFooter,
} from "Common/Components";
import { useTheme } from "@mui/material";
import { DrawerEventType } from "Common/Constants";
import { IDivision, ISnackbarState } from "Common/Interfaces";
import { CloseOutlined } from "@mui/icons-material";
import { UseBugTracker, UseFileUpload } from "./Hooks";
import { DragAndDropArea } from "BugTracker/Common/Components/FileUpload/DragAndDropArea";
import { useAppDispatch } from "Store";
import { resetFileUploads } from "BugTracker/Store/FileSlice";
import { Formik } from "formik";
import { CreateBugValidatorSchema } from "./Validations";
import { initialValues } from "./Constants";
import { FileExtensionAvatar, FileListWithIcon } from "BugTracker/Common/Components";
import { DialogType } from "BugTracker/Common/Constants/DialogType";

interface ICreateBugForm {
  onClose: () => void;
  drawerCloseEvent: () => void;
  onSubmitSnackbar: (state: ISnackbarState) => void;
}

export const CreateBugForm: React.FC<ICreateBugForm> = (props) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { onClose, drawerCloseEvent, onSubmitSnackbar } = props;

  const [divisionSearchText, setDivisionSearchText] = useState<string>("");

  const {
    createBug,
    divisionsQuery,
    setDivisionsQuery,
    allowedFileTypes,
  } = UseBugTracker();

  const {
    viewUpload,
    stateFiles,
    isNotAllowed,
    selectedFiles,
    setViewUpload,
    setSelectedFiles,
    onDragEnterEventHandler,
    onDragLeaveEventHandler,
    onDragOverEventHandler,
    onUploadReplaceEventHandler,
    restrictedFiles,
    setRestrictedFiles,
    hasUploadProcessStarted,
    setHasUploadProcessStarted,
    onDropEventHandler,
    errorTexts,
    setErrorTexts,
    clearFileUploadProcess,
    submitBug,
    onFileChangeEventHandler,
    onConfirmNotification,
  } = UseFileUpload({
    isAllowedDragAndDrop: true,
    fileChangeEventHandler: () => {},
    onSubmitSnackbar,
    drawerCloseEvent,
    allowedFileTypes,
  });

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={CreateBugValidatorSchema}
        validateOnMount
        validateOnChange
        validateOnBlur
        onSubmit={async (values) => {
          submitBug(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValidating, ...rest }) => (
          <>
            <FormContent>
              <$Grid container sx={{ mb: 2 }} rowSpacing={2}>
                <$Grid item xs={12} md={12}>
                  <$TextField
                    fullWidth
                    size="small"
                    id="title"
                    name="title"
                    label="Title"
                    value={values.title}
                    helperText={touched.title && errors.title}
                    error={touched.title && Boolean(errors.title)}
                    data-testid="title-input"
                    inputProps={{ maxLength: 100 }}
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </$Grid>

                <$Grid item xs={12} md={12}>
                  <$TextField
                    fullWidth
                    size="small"
                    id="description"
                    name="description"
                    label="Description"
                    value={values.description}
                    helperText={touched.description && errors.description}
                    required
                    multiline
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={8}
                    error={touched.description && Boolean(errors.description)}
                    data-testid="description-input"
                    inputProps={{ maxLength: 1000 }}
                  />
                </$Grid>

                <$Grid item xs={12} md={12}>
                  <DragAndDropArea
                    onDragEnterEvent={onDragEnterEventHandler}
                    onDragLeaveEvent={onDragLeaveEventHandler}
                    onDragOverEvent={onDragOverEventHandler}
                    onDropEvent={(event) => onDropEventHandler(event, allowedFileTypes)}
                    onFileChangeEvent={onFileChangeEventHandler}
                  />
                </$Grid>

                <$Grid item xs={12} md={12}>
                  <$List
                    sx={{
                      p: 0,
                      mt: 3,
                    }}
                  >
                    {selectedFiles.map((file, index) => (
                      <$ListItem
                        sx={{
                          p: 1,
                          ".MuiListItemSecondaryAction-root": {
                            right: 0,
                          },
                        }}
                        key={index}
                        secondaryAction={
                          <$IconButton
                            size="small"
                            onClick={() => {
                              setSelectedFiles((prev) => {
                                prev.splice(index, 1);
                                return [...prev];
                              });
                            }}
                          >
                            <CloseOutlined fontSize="small" color="secondary" />
                          </$IconButton>
                        }
                      >
                        <$Stack spacing={2} direction="row" alignItems="center" data-testid="application-avatar">
                          <FileExtensionAvatar fileFormat={file.type?.split("/")[1]} />

                          <$Stack sx={{ pr: 3 }}>
                            <$Typography variant="h5" color={theme.palette.primary.main}>
                              {file.name}
                            </$Typography>
                            <$Typography variant="caption" noWrap color={theme.palette.grey[500]}>
                              {file.size} bytes
                            </$Typography>
                          </$Stack>
                        </$Stack>
                      </$ListItem>
                    ))}
                  </$List>
                </$Grid>
              </$Grid>
            </FormContent>
            <FormFooter
              drawerStatusEvent={(event) => {
                if (event === DrawerEventType.Save) {
                  handleSubmit();
                } else if (event === DrawerEventType.Cancel) {
                  dispatch(resetFileUploads());
                  onClose();
                }
              }}
              disableSaveBtn={!rest.isValid || hasUploadProcessStarted}
              isLoading={isSubmitting || hasUploadProcessStarted}
              errorTexts={errorTexts}
            />
            {Boolean(viewUpload === DialogType.InvalidFile) && (
              <ConfirmationDialog
                isOpen={Boolean(viewUpload === DialogType.InvalidFile)}
                title={"Unsupported file types!"}
                message={
                  <FileListWithIcon
                    fileNames={restrictedFiles.map(({ name }) => name)}
                    message={"Below file type are not supported."}
                  />
                }
                onClickAction={onConfirmNotification}
              />
            )}
            {Boolean(viewUpload === DialogType.ExcessLimit) && (
              <ConfirmationDialog
                isOpen={Boolean(viewUpload === DialogType.ExcessLimit)}
                title={"File count exceeded!"}
                message={
                  <FileListWithIcon
                    fileNames={restrictedFiles.map(({ name }) => name)}
                    message={"More than 5 files cannot be selected."}
                  />
                }
                onClickAction={onConfirmNotification}
              />
            )}
          </>
        )}
      </Formik>
    </>
  );
};
