import { IBug } from "BugTracker/Common/Interfaces";

export const initialValues: IBug = {
  title: "",
  description: "",
  attachments: [],
  divisionCode: "",
  application: "callcenter",
  bugId: 0,
};
