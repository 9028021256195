import { styled, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";

const StyledListItemButton = styled(ListItemButton)<{
  to?: string;
  component?: typeof NavLink;
}>(({ theme }) => ({
  "& .MuiListItemText-root": {
    color: theme.sidebar.textColor,
  },
  "& .MuiListItemIcon-root": {
    color: theme.sidebar.menuItemIconColor,
    svg: {
      width: "18px",
      height: "18px",
    },
  },
  "&.Mui-selected": {
    backgroundColor: theme.sidebar.menuItemColorActive,
    ":hover": {
      backgroundColor: theme.sidebar.menuItemColorActive,
    },
    "& .MuiListItemText-root": {
      color: theme.sidebar.menuItemTextColorActive,
    },
    "& .MuiListItemIcon-root": {
      color: theme.sidebar.menuItemTextColorActive,
    },
  },
}));

interface SideBarMenuButtonProps {
  name: string;
  icon?: JSX.Element;
  isActive: boolean;
  isDisabled?: boolean;
  to?: string;
}

export const SideBarMenuButton: React.FC<SideBarMenuButtonProps> = ({ icon, name, isActive, isDisabled, to }) => {
  return (
    <StyledListItemButton selected={isActive} disabled={isDisabled} component={NavLink} to={to}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}

      <ListItemText primary={name} />
    </StyledListItemButton>
  );
};
