import { $Box } from "Common/Components";
import { useTheme } from "@mui/material/styles";

const TestEnvBanner = () => {
  const theme = useTheme();
  if (`${process.env.REACT_APP_TEST_ENV_MESSAGE}`.length > 0) {
    return (
      <$Box
        sx={{
          position: "absolute",
          left: "0",
          right: "0",
          top: "0",
          backgroundColor: theme.palette.warning.light,
          height: "6px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <$Box
          sx={{
            display: "block",
            backgroundColor: theme.palette.warning.light,
            position: "absolute",
            px: 2,
            fontSize: "0.75rem",
          }}
        >
          {process.env.REACT_APP_TEST_ENV_MESSAGE}
        </$Box>
      </$Box>
    );
  }

  return null;
};

export default TestEnvBanner;
