import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import "./PhoneNumber.scss";
import { $FormControl, $FormHelperText } from "../MUI";

interface IPhoneNumberProps {
  name: string;
  id?: string;
  value: string;
  label: string;
  defaultCountry?: string;
  onlyCountries?: Array<string>;
  excludeCountries?: Array<string>;
  preferredCountries?: Array<string>;
  autoFormat?: boolean;
  onChange: any;
  placeholder?: string;
  required?: boolean;
  helperText?: any;
  sx?: any;
  formControlSx?: SxProps;
  fullWidth: boolean;
  [key: string | number | symbol]: unknown;
}

export const $PhoneNumberInput = (props: IPhoneNumberProps) => {
  const {
    name,
    label,
    value,
    required,
    onlyCountries,
    onChange,
    helperText,
    fullWidth = true,
    formControlSx,
    ...rest
  } = props;

  const handleChange = (value: string, info: any) => {
    if (value === "") {
      onChange("");
    } else {
      onChange(`+${value}`);
    }
  };
  const theme: Theme = useTheme();

  useEffect(() => {
    const flagDropDownElements = document.getElementsByClassName("selected-flag");

    if (flagDropDownElements) {
      Array.from(flagDropDownElements).forEach((flag) => flag.setAttribute("tabindex", "-1"));
    }
  });

  return (
    <$FormControl
      fullWidth={fullWidth}
      size="small"
      sx={{
        ...formControlSx,
        ".special-label::after": required
          ? {
              content: "' *'",
              color: theme.colors.error.main,
            }
          : {},
      }}
    >
      <PhoneInput
        country={"us"}
        value={value}
        preferredCountries={["us", "ca"]}
        onlyCountries={onlyCountries}
        specialLabel={label}
        inputProps={{
          name: name,
          required: required,
          "data-testid": rest["data-testid"],
        }}
        inputStyle={{
          height: "20px",
          width: "100%",
          borderColor: theme.colors.alpha.black[30],
          fontSize: theme.typography.body1.fontSize,
          borderRadius: 0,
          fontFamily: ["Open Sans", "adobe-garamond-pro"].join(","),
        }}
        containerStyle={{
          fontSize: theme.typography.body1.fontSize,
          fontFamily: ["Open Sans", "adobe-garamond-pro"].join(","),
        }}
        containerClass="muiphone"
        dropdownStyle={{
          width: "250px",
          borderRadius: 0,
          color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
          boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        }}
        onChange={handleChange}
        {...rest}
      />
      <$FormHelperText
        sx={{
          padding: "3px 0px",
        }}
        message={rest.error && helperText}
      />
    </$FormControl>
  );
};
