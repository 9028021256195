import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, useTheme } from "@mui/material";
import { UserOutlined } from "Icons/Icons";
import { HeaderListItem, HeaderPopup } from "../Components";
import { $Divider, DataGridLoading } from "Common/Components";
import { LogoutOutlined, PermIdentityRounded } from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "authConfig";
import { useHeaderPopup } from "../Hooks";
import { useEffect } from "react";
import signalRService from "SignalR/signalRInstance";
import { useGetMyDetailsQuery } from "Store/Services/Users";
import { removeCookie } from "Cookies/CookieUtils";

interface IProfile {
  handleProfileDrawerOpen: () => void;
}
export const Profile: React.FC<IProfile> = (props) => {
  const { handleProfileDrawerOpen } = props;
  const { anchorRef, open, handleToggle, handleClose } = useHeaderPopup();
  const { instance } = useMsal();
  const theme = useTheme();

  const handleLogout = async () => {
    // Retrieve the local account ID
    let localAccountId = instance.getActiveAccount()?.localAccountId;
    console.log("calling handleLogout with accountId:", localAccountId);

    if (localAccountId) {
      try {
        // Ensure the SignalR connection is established
        if (!signalRService.isConnected()) {
          await signalRService.startConnection();
        }
        // Send the logout request and wait for it to complete
        await signalRService.sendLogoutRequest(localAccountId);

        localStorage.setItem('isUserLoggedOut', 'true');
        removeCookie('currentuserid');

        // Only call logoutRedirect after the logout request is successfully sent
        instance.logoutRedirect({
          account: instance.getActiveAccount(),
          postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        });
      } catch (error) {
        console.error("Error handling logout:", error);
        // Handle any errors that occur during connection or logout request
      }
    } else {
      console.error("No active account found for logout.");
    }
  };

  const { data: userDetails, isLoading: isUserDetailsLoading } = useGetMyDetailsQuery({});

  return (
    <>
      <IconButton
        onClick={handleToggle}
        ref={anchorRef}
        size="small"
        sx={(theme) => ({
          color: theme.palette.common.white,
          padding: 1.3,
        })}
      >
        <UserOutlined sx={{ fontSize: "1.2rem" }} />
      </IconButton>

      <HeaderPopup anchorEl={anchorRef.current} open={open} handleClose={handleClose}>
        <DataGridLoading isLoading={isUserDetailsLoading}>
          <List
            component="nav"
            sx={{
              px: 0,
              pb: 2,
              "& .MuiListItemIcon-root": {
                color: theme.palette.primary.main,
              },
            }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  alt="profile user"
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                  }}
                >
                  <UserOutlined sx={{ fontSize: "1.2rem" }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={userDetails?.displayName}
                secondary={`${userDetails?.entity?.displayName} - ${userDetails?.role?.displayName} `}
              />
            </ListItem>
            <$Divider variant="fullWidth" component="li" sx={{ mb: 2 }}></$Divider>
            <HeaderListItem primaryText="My Profile" icon={<PermIdentityRounded />} onClick={handleProfileDrawerOpen} />
            <HeaderListItem primaryText="Log out" icon={<LogoutOutlined />} onClick={handleLogout} />
          </List>
        </DataGridLoading>
      </HeaderPopup>
    </>
  );
};
