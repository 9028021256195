import { useTheme } from "@mui/material/styles";
import { $Box, $Stack, $Typography } from "Common/Components";
import React from "react";
import { FileExtensionAvatar } from "../FileExtensionAvatar";

interface IFileListWithIcon {
  fileNames: string[];
  message?: string;
}

export const FileListWithIcon: React.FC<IFileListWithIcon> = ({
  fileNames,
  message = "FILE_UPLOAD_RESTRICTION_MESSAGE",
}) => {
  const theme: any = useTheme();

  return (
    <>
      <p>{message}</p>
      {fileNames.map((fileName) => (
        <React.Fragment key={fileName}>
          <$Box
            sx={{
              border: `1px solid ${theme.palette.grey[200]}`,
              backgroundColor: "#fff",
              padding: "10px 15px",
              marginBottom: "1px",
              transition: "all 0.3s",
              "&:hover": {
                borderColor: theme.palette.primary.main,
              },
              mb: 0.5,
            }}
          >
            <$Stack direction="row" justifyContent="space-between" alignItems="center" columnGap={2}>
              <$Stack direction="row" alignItems="center" sx={{ minWidth: "0" }}>
                <FileExtensionAvatar fileFormat={fileName.split(".")[fileName.split(".").length - 1]} />

                <$Typography variant="body1" sx={{ lineHeight: "1.5", ml: 1, flex: "1" }} noWrap>
                  {fileName}
                </$Typography>
              </$Stack>
            </$Stack>
          </$Box>
        </React.Fragment>
      ))}
    </>
  );
};
