import { Box, Stack, Typography, alpha, useTheme } from "@mui/material";
import UploadImage from "Common/StateMessagesSvg/UploadImage";
import { ChangeEvent, useRef, useState } from "react";

interface IDragAndDropArea {
  // isOnTopOfFiles: boolean;
  // isVisible: boolean;
  // isNotAllowed: boolean;
  onDragEnterEvent: (event: React.DragEvent<HTMLDivElement> | undefined) => void;
  onDragLeaveEvent: (event: React.DragEvent<HTMLDivElement> | undefined) => void;
  onDragOverEvent: (event: React.DragEvent<HTMLDivElement> | undefined) => void;
  onDropEvent: (event: React.DragEvent<HTMLDivElement> | undefined) => void;
  onFileChangeEvent: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const DragAndDropArea: React.FC<IDragAndDropArea> = (props) => {
  const { onDragEnterEvent, onDragLeaveEvent, onDragOverEvent, onDropEvent, onFileChangeEvent } = props;
  const theme = useTheme();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [dropOverStyle, setdropOverStyleFile] = useState<string>("#fff");

  const onDragEnterEventHandler = (event: React.DragEvent<HTMLDivElement> | undefined) => {
    event?.stopPropagation();
    event?.preventDefault();
    setdropOverStyleFile("#F0F0F0");
    onDragEnterEvent(event);
  };

  const onDragOverEventhandler = (event: React.DragEvent<HTMLDivElement> | undefined) => {
    event?.stopPropagation();
    event?.preventDefault();
    onDragOverEvent(event);
  };

  const onDropEventHandler = (event: React.DragEvent<HTMLDivElement> | undefined) => {
    event?.stopPropagation();
    event?.preventDefault();
    setdropOverStyleFile("#fff");
    onDropEvent(event);
  };

  const fileChangeEventHandler = (e: ChangeEvent<HTMLInputElement>) => {
    // clearFileUploadProcess();
    onFileChangeEvent(e);

    if ((fileInputRef as any)?.current) {
      (fileInputRef as any).current.value = "";
    }
  };

  const uploadArea = (
    <Stack
      flex={1}
      onDragEnter={onDragEnterEventHandler}
      onDragOver={onDragOverEventhandler}
      onDrop={onDropEventHandler}
      onDragLeave={onDragLeaveEvent}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: dropOverStyle,
        position: "relative",
        padding: 2,
        border: `1px solid ${theme.palette.divider}`,
      }}
      onClick={() => fileInputRef.current?.click()}
    >
      <UploadImage />
      <Typography variant="subtitle1">
        {"Drag file(s) to this area to upload"}
      </Typography>
      <input multiple type="file" ref={fileInputRef} accept="*" hidden onChange={fileChangeEventHandler} />
    </Stack>
  );
  return (
    <DragAndDropAreaTransparent>
      <>{uploadArea}</>
    </DragAndDropAreaTransparent>
  );
};

const DragAndDropAreaTransparent: React.FC<{ children: JSX.Element }> = (props) => {
  const { children } = props;
  return <Box>{children}</Box>;
};
