import { $Box } from "./MUI";

interface IFormContent {
  children: JSX.Element;
  width?: string;
  sx?: object;
}
export const FormContent: React.FC<IFormContent> = (props) => {
  const { children, sx } = props;

  return (
    <$Box
      sx={{
        py: 3,
        px: 4,
        overflow: "auto",
        flex: 1,
        ...sx,
      }}
    >
      {children}
    </$Box>
  );
};
