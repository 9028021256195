export enum FILE_EXTENSIONS {
  PDF = "pdf",
  JPG = "jpg",
  JPEG = "jpeg",
  PNG = "png",
  MP4 = "mp4",
  NONE = "none",
  MOV = "mov",
  AVI = "avi",
}
