import React from "react";
import { FormHelperText, SxProps, Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";


interface IFormHelperTextProps {
  message?: React.ReactNode | string;
  sx?: SxProps<Theme> | undefined;
  required?: boolean;
  error?: boolean;
  [key: string | number | symbol]: unknown;
}

const $FormHelperText = (props: IFormHelperTextProps) => {
  const { message, sx, error=true, ...rest } = props;
  const theme = useTheme();
  const errorStyle = {
    width: '100%',   
    padding:'3px 5px'
  };
  return message ? <>

      <FormHelperText children={message} sx={sx} error={error} {...rest}  style={errorStyle}/>
  </> : null;

};

export default $FormHelperText;
