import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import { $Box } from "Common/Components";
import { HEADER_HEIGHT } from "Common/Constants";
import { Header } from "./Header";
import { MyProfile, useMyProfile } from "./MyProfile";

const MainLayout = () => {
  const props = useMyProfile();
  return (
    <>
      <Box sx={{ display: "flex", width: "100%", overflow: "hidden" }}>
        <Header
          handleProfileDrawerOpen={() => {
            props.setDrawer({ isOpen: true, title: "My Profile", type: "VIEW" });
          }}
        />
        <Box component="main" sx={{ width: "100%" }}>
          <Toolbar />
          <$Box
            sx={{
              height: `calc(100vh - ${HEADER_HEIGHT})`,
            }}
          >
            <Outlet />
          </$Box>
        </Box>
      </Box>
      <MyProfile {...props} />
    </>
  );
};

export default MainLayout;
