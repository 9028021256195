import { useTheme } from "@mui/material";
import { $CardMedia, $Link } from "Common/Components";
import { useMatchDownMd } from "Common/Hooks";

interface IOurBrandPanel {
  logoURL: string;
  linkURL: string;
}

export const OurBrandPanel: React.FC<IOurBrandPanel> = (props) => {
  const theme = useTheme();
  const { matchDownMd } = useMatchDownMd();

  const { logoURL, linkURL } = props;
  return (
    <$Link
      href={linkURL}
      target="_blank"
      rel="noreferrer"
      sx={{
        opacity: "1",
        transition: "all 0.3s",
        justifyContent: "flex-start",
        flexGrow: "0",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        Width: "160px",
        Height: "auto",
        cursor: "pointer",

        "&:hover": {
          backgroundColor: "transparent",
          opacity: "0.8",
        },
        [theme.breakpoints.down("sm")]: {
          height: "90px",
          padding: 2,
          gap: 3,
          ml: "0px !important",
        },
      }}
    >
      <$CardMedia
        component="img"
        image={""}
        sx={{ maxWidth: matchDownMd ? "120px" : "160px", Height: "auto", flexGrow: "1", objectFit: "contain" }}
        alt={""}
        src={logoURL}
      />
    </$Link>
  );
};
