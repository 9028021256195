export enum LABEL_VALUES {
  ENTITY_TYPE = "Entity Type",
  ENTITY_NAME = "Entity Name",
  ROLE = "Role",
  FIRST_NAME = "First Name",
  LAST_NAME = "Last Name",
  MOBILE_PHONE = "Mobile Phone",
  WORK_PHONE = "Work Phone",
  JOB_TITLE = "Job Title",
}
