import { MenuItems } from "Common/Components";
import { IContextMenuItem } from "Common/Interfaces";
import { EditOutlined } from "Icons/Icons";

export const getContextMenuItems = (menuItemClickEvent: (menuItem: MenuItems) => void): IContextMenuItem<MenuItems>[] => {
  const menuItems: IContextMenuItem<MenuItems>[] = [
    {
      event: MenuItems.UPDATE_INFO,
      icon: <EditOutlined fontSize="small" />,
      itemText: "Edit",
      onMenuItemClickEvent: () => menuItemClickEvent(MenuItems.UPDATE_INFO),
      showDivider: false,
    },
  ];
  return menuItems;
};
