import { ListItemButtonProps, styled, useTheme } from "@mui/material";
import { $ListItemButton, $ListItemText, $Tooltip, ApplicationAvatar } from "Common/Components";

const StyledListItemButton = styled($ListItemButton)(({ theme }) => ({
  paddingLeft: "0px",
  paddingRight: "0px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}));

interface ISideBarAppButton {
  applicationId: string;
  name: string;
  redirectURL: string;
  logoURL: string;
}

export const SideBarAppButtonMobile: React.FC<ISideBarAppButton & ListItemButtonProps> = (props) => {
  const theme = useTheme();
  const { applicationId, name, redirectURL, logoURL, ...other } = props;
  return (
    <$Tooltip title={name} placement="right" arrow>
      <StyledListItemButton {...other}>
        <ApplicationAvatar
          logoLink={logoURL}
          width="34px"
          height="34px"
          applicationId={applicationId.toString()}
          onlyAvatar
        />
        <$ListItemText
          primary={name}
          sx={{
            color: theme.palette.primary.main,
          }}
        />
      </StyledListItemButton>
    </$Tooltip>
  );
};
