import { Tooltip } from "@mui/material";
import { $Box, $ListItemText } from "Common/Components";

interface ILabelBox {
  primary: string;
  secondary: string;
  hideBorder?: boolean;
}

export const LabelBox: React.FC<ILabelBox> = (props) => {
  const { primary, secondary, hideBorder } = props;
  return (
    <$Box
      sx={(theme) => ({
        border: hideBorder ? "none" : `1px solid ${theme.palette.grey[300]}`,
        px: hideBorder ? 0 : 2,
        py: hideBorder ? 0 : 1,
        borderRadius: "6px",
        minWidth: "150px",
        width: "fit-content",
      })}
    >
      <span>
        <Tooltip title={secondary} arrow>
          <$ListItemText
            primary={primary}
            secondary={secondary}
            secondaryTypographyProps={{
              noWrap: true,
            }}
          />
        </Tooltip>
      </span>
    </$Box>
  );
};
