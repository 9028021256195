import { useTheme } from '@mui/material/styles';

const UploadFiles = () => {
    const theme = useTheme();
    
    return (

        <>
            <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill={theme.palette.primary.light} d="m76,41c0,20.43-16.57,37-37,37S2,61.43,2,41,18.57,4,39,4s37,16.57,37,37Z"/>
                <path fill={theme.palette.common.white} d="m56.04,17.41c-.83-.74-1.92-1.13-3.03-1.07h0s-5.94,0-5.94,0V3.99c0-1.05-.41-2.06-1.16-2.81-.74-.75-1.75-1.17-2.8-1.17h-23.5c-.1-.01-.2-.01-.3,0-.12.03-.24.07-.36.13l-.09.08c-.11.06-.22.14-.31.23l-1.4,1.42L7.42,11.68c-.2.2-.34.46-.39.74,0,.08,0,.16,0,.24v41.24c0,1.05.4,2.06,1.14,2.8.73.75,1.73,1.18,2.78,1.19h6.43v6.3c.06,1.13.55,2.19,1.39,2.96.83.77,1.93,1.17,3.06,1.13h31.17c1.14.04,2.25-.37,3.08-1.14.84-.78,1.33-1.85,1.37-2.99V20.47c0-.06,0-.11,0-.17-.08-1.11-.59-2.15-1.42-2.89Z"/>
                <path fill={theme.palette.primary.main} d="m22.85,28.81l4.94-4.45v13c0,.82.66,1.48,1.48,1.48s1.48-.66,1.48-1.48v-12.96l4.95,4.45c.61.55,1.56.49,2.1-.13.55-.61.49-1.56-.13-2.1l-7.19-6.43h0c-.27-.41-.73-.65-1.22-.65-.38,0-.75.13-1.03.39l-7.35,6.68c-.61.55-.67,1.49-.12,2.11s1.49.67,2.11.12l-.03-.03Z"/>
                <path fill={theme.palette.primary.main} d="m56.86,20.33c-.07-.96-.5-1.84-1.22-2.48-.72-.64-1.65-.97-2.61-.92h-.03s-6.53,0-6.53,0V3.99c0-.9-.35-1.75-.98-2.39-.64-.64-1.48-.99-2.38-1h-23.57s-.09-.01-.13,0c-.04.01-.09.03-.13.04l-.14.1c-.06.03-.12.08-.17.13L7.84,12.1c-.11.11-.19.25-.23.41,0,.03,0,.07,0,.1v.1s0,41.19,0,41.19c0,.9.34,1.75.97,2.39.63.64,1.47,1,2.37,1.01h7.02v6.89c.05.95.47,1.86,1.2,2.52.72.66,1.67,1,2.64.98h31.19c1.02.03,1.96-.31,2.68-.99.71-.66,1.15-1.6,1.18-2.58V20.47c0-.07,0-.1,0-.14Zm-10.39-1.62h4.3l-4.3,4.31v-4.31ZM10.94,55.52c-.88-.01-1.58-.73-1.58-1.61v-.15s0-40.63,0-40.63L18.71,3.66v7.02c0,.62-.49,1.14-1.12,1.16h-3.56c-.49,0-.89.4-.89.89s.4.89.89.89h3.54c.78,0,1.51-.31,2.07-.87.55-.56.85-1.29.85-2.07V2.39h22.61c.88.01,1.59.73,1.58,1.61v49.93c0,.87-.7,1.59-1.57,1.6H10.94Zm14.59,1.79l-5.77,5.77v-5.77h5.77Zm-3.67,8.6c-.06,0-.13,0-.19,0-.95,0-1.76-.69-1.91-1.65v-.09s0-.68,0-.68l6.19-6.2h6.21l-8.6,8.6h-1.7Zm2.1,0l8.6-8.6h6.27l-8.6,8.6h-6.27Zm31.11-1.68c-.13.98-.95,1.69-1.92,1.69-.06,0-.12,0-.18,0h-2.29l4.39-4.39v2.72Zm0-3.12l-4.8,4.8h-6.27l11.07-11.07v6.27Zm0-6.68l-11.48,11.48h-6.28l17.75-17.74v6.26Zm0-6.68l-9.59,9.59.02-.05-8.58,8.62h-6.27l8.6-8.6h3.83c.34,0,.68-.06,1-.17l1.66-.55.56-1.64c.11-.33.17-.68.18-1.03v-3.8l8.6-8.6v6.24Zm0-6.68l-8.6,8.6v-6.23l8.6-8.6v6.23Zm0-6.68l-8.6,8.6v-6.21l8.6-8.6v6.21Zm0-6.68l-8.6,8.6v-6.21l8.6-8.6v6.21Zm0-6.64l-8.6,8.6v-6.24l4.72-4.73h1.77c1.03-.11,1.95.6,2.1,1.63v.07s0,.08,0,.08v.58Z"/>
            </svg>
        </>
    );
};

export default UploadFiles;
