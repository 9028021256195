export enum CONFIRMATION_DIALOG_TYPES {
  DELETE = "delete",
  ACTIVATE = "activate",
  RESET = "reset",
  RESTORE = "restore",
  DEACTIVATE = "deactivate",
  WARNING = "warning",
  ENABLE = "enable",
  DISABLE = "disable",
}
