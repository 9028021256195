import { configureStore } from "@reduxjs/toolkit";
import { baseAPI } from "./BaseAPI";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { menuReducer } from "Layout/MainLayout/Slice";
import bugTrackerFileReducer from "../BugTracker/Store/FileSlice";
import { documentBaseAPI } from "./DocumentBaseAPI";

export const store = configureStore({
  reducer: {
    [baseAPI.reducerPath]: baseAPI.reducer,
    menu: menuReducer,
    bugTrackerFile: bugTrackerFileReducer,
    [documentBaseAPI.reducerPath]: documentBaseAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["file/addFile"],
        ignoredActionPaths: ["file"],
        ignoredPaths: ["file"],
        ignoreActions: true,
      },
    }).concat([baseAPI.middleware, documentBaseAPI.middleware]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
