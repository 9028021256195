import { IFormValues } from "../Interfaces";

export const INITIAL_FORM_VALUES: IFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobilePhone: "",
  workPhone: "",
  jobTitle: "",
};
