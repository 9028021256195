import { styled } from "@mui/material";
import { $ListItemButton, $ListItemIcon, $ListItemText } from "Common/Components";
import { MouseEventHandler } from "react";
import { NavLink } from "react-router-dom";

const StyledListItemButton = styled($ListItemButton)<{
  to?: string;
  component?: typeof NavLink;
}>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  "& .MuiListItemText-root": {
    marginLeft: "0px",
    marginTop: "3px",
    color: theme.palette.grey[600],
    "& .MuiListItemText-primary": {
      fontSize: "0.825rem",
    },
  },
  "& .MuiListItemIcon-root": {
    color: theme.palette.grey[600],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    svg: {
      width: "16px",
      height: "16px",
    },
  },
  ":hover": {
    background: theme.palette.grey[100],
  },
  "&.Mui-selected": {
    background: "none",
    ":before": {
      content: '""',
      left: "0",
      position: "absolute",
      width: "5px",
      height: "100%",
      backgroundColor: theme.palette.secondary.main,
    },
    ":hover": {
      background: theme.palette.grey[100],
    },
    "& .MuiListItemText-root": {
      color: theme.palette.primary.main,
      "& .MuiListItemText-primary": {
        // fontWeight: theme.typography.fontWeightBold,
      },
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
  },
}));

interface SideBarButtonProps {
  name: string;
  icon?: JSX.Element;
  isActive: boolean;
  isDisabled?: boolean;
  to?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const MiniSideBarButton: React.FC<SideBarButtonProps> = ({ icon, name, isActive, isDisabled, to, onClick }) => {
  return (
    <StyledListItemButton selected={isActive} disabled={isDisabled} component={NavLink} to={to} onClick={onClick}>
      {icon && <$ListItemIcon>{icon}</$ListItemIcon>}
      <$ListItemText primary={name} />
    </StyledListItemButton>
  );
};
