import { Stack } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { $Box, $Button, $LoadingButton, $Stack, $Typography } from "./MUI";
import { DrawerEventType } from "Common/Constants";

interface IFormFooter {
  drawerStatusEvent: (event: DrawerEventType) => void;
  children?: JSX.Element;
  areButtonsDisabled?: boolean;
  saveBtnText?: string;
  cancelBtnText?: string;
  backBtnText?: string;
  isBackButtonVisible?: boolean;
  disableSaveBtn?: boolean;
  isLoading?: boolean;
  cancelButtonOnRight?: boolean;
  errorTexts?: string[];
  isHideCancelButton?: boolean;
}
export const FormFooter: React.FC<IFormFooter> = (props) => {
  const {
    drawerStatusEvent,
    areButtonsDisabled = false,
    saveBtnText = "Save",
    cancelBtnText = "Cancel",
    backBtnText = "Back",
    isBackButtonVisible,
    disableSaveBtn = false,
    isLoading,
    cancelButtonOnRight = false,
    errorTexts,
    isHideCancelButton = false,
  } = props;
  const theme = useTheme();

  return (
    <$Box
      sx={{
        px: 4,
        py: 2,
        borderTop: `1px solid ${theme.palette.divider}`,
      }}
      display="flex"
      flex={0}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {(errorTexts?.length ?? 0) > 0 && (
          <$Stack
            sx={{
              flex: "1",
            }}
          >
            {errorTexts?.map((errorText, index) => (
              <$Typography key={index} variant="body1" color={theme.palette.error.main}>
                {errorText}
              </$Typography>
            ))}
          </$Stack>
        )}
        {cancelButtonOnRight && (
          <$Button
            variant="text"
            color="secondary"
            size="small"
            disabled={areButtonsDisabled}
            onClick={() => drawerStatusEvent(DrawerEventType.Cancel)}
            data-testid="cancel-btn"
          >
            {cancelBtnText}
          </$Button>
        )}

        <Stack
          spacing={2}
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "flex-end",
            flex: "1",
          }}
        >
          {!cancelButtonOnRight && !isHideCancelButton && (
            <$Button
              variant="text"
              color="secondary"
              size="small"
              disabled={areButtonsDisabled}
              onClick={() => drawerStatusEvent(DrawerEventType.Cancel)}
              data-testid="cancel-btn"
            >
              {cancelBtnText}
            </$Button>
          )}

          {isBackButtonVisible && (
            <$Button
              variant="text"
              color="primary"
              disabled={areButtonsDisabled}
              onClick={() => drawerStatusEvent(DrawerEventType.Back)}
              data-testid="back-btn"
              size="small"
            >
              {backBtnText}
            </$Button>
          )}

          <$LoadingButton
            variant="contained"
            color="primary"
            size="small"
            disabled={areButtonsDisabled || disableSaveBtn}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              drawerStatusEvent(DrawerEventType.Save);
            }}
            loading={isLoading}
            loadingPosition={isLoading ? "start" : undefined}
            startIcon={isLoading ? <$Box sx={{ px: 1 }}> </$Box> : undefined}
            data-testid="save-btn"
          >
            <span>{saveBtnText}</span>
          </$LoadingButton>
        </Stack>
      </Stack>
    </$Box>
  );
};
