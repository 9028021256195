import { IStatusData } from "../Interfaces";

export const transformErrorResponse = (response: any): IStatusData<{}> => {
  try {
    switch (response.status) {
      case 415:
        return {
          status: response.status,
          message: response.data.title,
        };
      case 400:
        if (response.data.errors) {
          let messages: string[] = [];
          Object.keys(response.data.errors).forEach((key) =>
            messages.push(response.data.errors[key].map(({ message }: any) => message))
          );
          if (response.data.errors["Validation error"]) {
            return {
              status: response.data.status,
              message: Object.values(response.data.errors["Validation error"]).join(", "),
            };
          }
          return {
            status: response.status,
            message: messages.join(", "),
          };
        } else if (response.data.messages) {
          return {
            status: response.status,
            message: response.data.messages[0],
            response: response.data,
          };
        }
        return {
          status: response.status,
          message: response.data.message[0],
        };
      case 404:
        if (response.data.errors) {
          return {
            status: response.status,
            message: Object.values(response.data.errors).join(", "),
          };
        }
        return {
          status: "404",
          message: "Not found endpoint",
        };
      case 500:
        if (response.data.errors) {
          return {
            status: response.status,
            message: Object.values(response.data.errors).join(", "),
          };
        }
        if (response.data.hasOwnProperty("detail")) {
          return {
            status: response.status,
            message: response.data.detail,
          };
        }
        return {
          status: response.status,
          message: `${response.data.Type} - ${response.data.Message}`,
        };
      default:
        return response;
    }
  } catch (error) {
    console.error(error);
    return response;
  }
};
