import { IApplication, IMyDetailsEditRequest, IPaginatedList, ISearchParam, IUser } from "Common/Interfaces";
import { baseAPI } from "Store";
import { SERVICE_TAG_TYPES } from "Store/Constants";
import { transformErrorResponse } from "./Functions";

const baseURL = "users";
const controller = "users";
export const authorizationAPI = baseAPI.injectEndpoints({
  endpoints: (build) => ({
    getUserApplications: build.query<IPaginatedList<IApplication>, ISearchParam>({
      query: ({ offset, limit, sortBy, orderBy, isDeleted, search }) =>
        `${baseURL}/applications?Offset=${offset}&Limit=${limit}&SortBy=${sortBy}&OrderBy=${orderBy}&IsDeleted=${isDeleted}&Search=${search}`,
      transformResponse: (response: IPaginatedList<IApplication>, meta, arg) => {
        return { ...response, data: response.data.map((x) => ({ ...x, key: x.id })) };
      },
      providesTags: (result, error, query) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: SERVICE_TAG_TYPES.USER_APPLICATIONS, id } as const)),
              { type: SERVICE_TAG_TYPES.USER_APPLICATIONS, id: "LIST" },
            ]
          : [],
    }),

    getMyDetails: build.query<IUser, {}>({
      query: () => `${controller}/me`,
      transformResponse: (response: IUser) => response,
      providesTags: (result) => (result ? [{ type: SERVICE_TAG_TYPES.USER_DETAILS, id: "OBJECT" }] : []),
    }),
    updateMyDetails: build.mutation<IUser, IMyDetailsEditRequest>({
      query(userDetails) {
        return {
          url: `${controller}/me`,
          method: "PUT",
          body: userDetails,
        };
      },
      transformResponse: (response: IUser, meta, arg) => response,
      transformErrorResponse: (response: any = {}, meta, arg) => transformErrorResponse(response),
      invalidatesTags: (result, error, arg) => (result ? [{ type: SERVICE_TAG_TYPES.USER_DETAILS, id: "OBJECT" }] : []),
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserApplicationsQuery, useGetMyDetailsQuery, useUpdateMyDetailsMutation } = authorizationAPI;
