import React, { useState } from "react";
import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { themeCreator } from "./base";

export const ThemeContext = React.createContext((themeName: string): void => {});

interface ILayoutProps {
  children?: React.ReactNode;
}
const ThemeProviderWrapper: React.FC<ILayoutProps> = (props) => {
  const curThemeName = localStorage.getItem("appTheme") || "PureLightTheme";
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName: string): void => {
    localStorage.setItem("appTheme", themeName);
    _setThemeName(themeName);
  };
  const { children } = props;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};

export default ThemeProviderWrapper;
