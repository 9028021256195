import { Box, Drawer } from "@mui/material";
import { HEADER_HEIGHT, SIDEBAR_WIDTH } from "Common/Constants";
import React from "react";
import Logo from "./Logo";

interface IMobileSidebar {
  isOpen: boolean;
  children: JSX.Element;
}
export const MobileSidebar: React.FC<IMobileSidebar> = (props) => {
  const { isOpen, children } = props;
  return (
    <Drawer
      open={isOpen}
      anchor="left"
      sx={(theme) => ({
        flexShrink: { md: 0 },
        zIndex: 1300,
        ".MuiDrawer-paper": {
          [theme.breakpoints.down("md")]: {
            width: "210px",
          },
        },
      })}
    >
      <Box sx={{ width: "100%", height: "100%" }} display="flex" flexDirection="column">
        <Box
          sx={(theme) => ({
            px: 3,
            minHeight: HEADER_HEIGHT,
            backgroundColor: theme.palette.secondary.main,
            // backgroundColor: theme.palette.secondary.main,
          })}
          display="flex"
          flex={0}
        >
          <Logo to={process.env.REACT_APP_LANDING_PAGE_URL} />
        </Box>

        {children}
      </Box>
    </Drawer>
  );
};
