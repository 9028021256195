import React from "react";
import { useTheme } from "@mui/material/styles";
import { Avatar } from "@mui/material";
import { FILE_EXTENSIONS } from "BugTracker/Common/Constants";
import { getFileIconByExtension, getFileIconColor } from "BugTracker/Common/Functions";

interface IFileExtensionAvatar {
  fileFormat: FILE_EXTENSIONS | string | null;
  thumbnail?: string;
  sx?: object;
  mediumSize?: boolean;
}

export const FileExtensionAvatar: React.FC<IFileExtensionAvatar> = (props) => {
  const { fileFormat, sx, mediumSize, thumbnail } = props;
  const theme = useTheme();

  return (
    <Avatar
      draggable={false}
      sx={{
        fontSize: "0.5rem",
        bgcolor: getFileIconColor(fileFormat, theme),
        width: mediumSize ? 42 : 26,
        height: mediumSize ? 42 : 26,
        fontWeight: 600,
        color: "#fff",
        "& .MuiSvgIcon-root": {
          fontSize: "0.9rem",
        },
        ...sx,
      }}
      src={thumbnail}
      variant="square"
    >
      {getFileIconByExtension(fileFormat)}
    </Avatar>
  );
};
