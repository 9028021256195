import { SERVICE_TAG_TYPES } from "Store/Constants";
import { baseAPI as adminBaseAPI } from "Store/BaseAPI";
import { Action, AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import axios, { AxiosRequestConfig } from "axios";
import { RootState } from "Store/Store";
import {
  cancelUploadSingleFile,
  generateFileNumber,
  setFileState,
  setProgress,
  updateFileDetails,
} from "BugTracker/Store/FileSlice";
import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, amiAdminApiTokenRequest as amiApiTokenRequest, loginRequest } from "authConfig";
import { IBug, IFileState, IFileUploadStraightData } from "BugTracker/Common/Interfaces";
import { FileState } from "BugTracker/Common/Constants/FileUpload";
import { transformErrorResponse } from "Store/Services/Functions";

const controller = "bugtracker";

export const bugTrackerAPI = adminBaseAPI.injectEndpoints({
  endpoints: (build) => ({
    createBug: build.mutation<{ bugId: number }, FormData>({
      query: (bug) => ({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: controller,
        method: "POST",
        body: bug,
      }),
      invalidatesTags: (result, error, arg) => (result ? [SERVICE_TAG_TYPES.BUGTRACKER] : []),
      transformErrorResponse: transformErrorResponse,
    }),
  }),
  overrideExisting: false,
});

const manageUpload = async (
  dispatch: any,
  getState: () => RootState,
  title: string,
  description: string,
  application: string = "callcenter",
  currentIndex: number,
  files: File[],
  //fileNumberNames: Array<{ fileNumber: number; fileData: IFileUploadStraightData }>,
  controller: string = "bugtracker",
  divisionCode?: string
) => {
  let token = "";
  const msalInstance = new PublicClientApplication(msalConfig);
  try {
    token = (await msalInstance.acquireTokenSilent(amiApiTokenRequest)).accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      token = (await msalInstance.acquireTokenPopup(amiApiTokenRequest)).accessToken;
    }
  }
  if (token === "") {
    msalInstance.loginRedirect(loginRequest).catch((error) => console.log("loginError", error));
  }

  const { bugTrackerFile: file } = getState();
  const fileNumber = generateFileNumber(file);
  const {
    controller: updatedController,
    fileState: updatedFileState,
    fileName,
    newFileName,
  } = file.find((fileItem: IFileState) => fileItem.fileNumber === fileNumber) ?? {};

  let updatedFormData = new FormData();
  updatedFormData.append("title", title);
  updatedFormData.append("description", description);
  updatedFormData.append("application", "documentportal");
  updatedFormData.append("divisionId", divisionCode ?? "");
  files.map((file) => updatedFormData.append("attachments", file, file.name));
  // updatedFormData.append("fileTypeAttributes", fileNumberNames[currentIndex].fileData.jsonBodyData ?? "");
  // updatedFormData.append("file", fileNumberNames[currentIndex].fileData.file, newFileName ?? fileName);

  if (updatedFileState === FileState.Cancelled) {
    // call for next api call
    // if (currentIndex + 1 <= fileNumberNames.length - 1) {
    //   manageUpload(dispatch, getState, divisionId, currentIndex + 1, fileNumberNames, controller);
    // } else {
    //   // the final file is cancelled
    //   /* dispatch(
    //     baseAPI.util.invalidateTags([
    //       SERVICE_TAG_TYPES.SEARCH_QUERY,
    //       SERVICE_TAG_TYPES.SEARCH_WITH_FILTERS,
    //       SERVICE_TAG_TYPES.DOCUMENT_LIST,
    //     ])
    //   );*/
    // }
  } else {
    try {
      dispatch(setFileState({ fileNumber: fileNumber, fileState: FileState.Uploading }));

      const config: AxiosRequestConfig<any> = {
        signal: updatedController?.signal,
        onUploadProgress: (progressEvent) => {
          dispatch(
            setProgress({
              progress: Math.round((progressEvent.loaded * 100) / (progressEvent?.total ?? 1)),
              fileNumber: fileNumber,
            })
          );
        },
        headers: {
          "content-type": "multipart/form-data",
          authorization: `Bearer ${token}`,
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBSCRIPTION_KEY,
          fileDisplayname: newFileName ?? fileName,
          title,
          description,
          application,
          divisionCode,
          isSingleFileUpload: "false",
        },
      };
      const url = `${process.env.REACT_APP_ADMIN_API_BASE_URL}${controller}`;
      console.log("URL: " + controller);

      axios
        .post(url, updatedFormData, config)
        .then((response) => {
          if (response.status === 201) {
            console.log("dispatching file status to: " + fileNumber);
            dispatch(setFileState({ fileNumber: fileNumber, fileState: FileState.UploadCompleted }));
          }
        })
        .catch((error) => {
          console.log("upload-catch", error);
        })
        .finally(() => {
          // call for next api call
          // if (currentIndex + 1 <= fileNumberNames.length - 1) {
          //   manageUpload(dispatch, getState, divisionId, currentIndex + 1, fileNumberNames, controller);
          // }
        });
    } catch (error: any) {
      console.log(error);
      dispatch(cancelUploadSingleFile(fileNumber));

      // call for next api call
      // if (currentIndex + 1 <= fileNumberNames.length - 1) {
      //   manageUpload(dispatch, getState, divisionId, currentIndex + 1, fileNumberNames, controller);
      // }
    }
  }
  // if (currentIndex + 1 === fileNumberNames.length) {
  //   dispatch(updateListRefreshedId({}));
  // }
};

export const uploadFilesSequentially =
  (bug: IBug, filesArray: File[], controller: string = "bugtracker"): ThunkAction<void, RootState, unknown, Action> =>
  async (dispatch, getState) => {
    let fileNumberNames: Array<{ fileNumber: number; fileData: IFileUploadStraightData }> = [];

    filesArray.forEach(async (fileData) => {
      const formData = new FormData();
      //formData.append("fileTypeAttributes", fileData.jsonBodyData ?? "");
      formData.append("file", fileData, fileData.name);

      const fileName = fileData.name;

      //fileNumberNames.push({ fileNumber: fileData.fileNumber ?? 0, fileData });

      dispatch(
        updateFileDetails({
          newFormData: formData,
          fileNumber: 0,
          newFileName: fileName,
        })
      );
    });
    manageUpload(
      dispatch,
      getState,
      bug.title ?? "",
      bug.description ?? "",
      bug.application,
      0,
      filesArray,
      controller,
      bug.divisionCode
    );
  };

export const { useCreateBugMutation } = bugTrackerAPI;
