import { Configuration, PopupRequest, BrowserCacheLocation } from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";

const concentScopes: string[] = [`${process.env.REACT_APP_USER_READ_SCOPE}` ?? ""];

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
    authority: `https://${process.env.REACT_APP_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_TENANT_NAME}.onmicrosoft.com/${process.env.REACT_APP_SIGNIN_SIGNUP_FLOW_NAME}`, // `https://login.microsoftonline.com/${your_tenant_id}`,
    knownAuthorities: [`${process.env.REACT_APP_TENANT_NAME}.b2clogin.com`],
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["openid", "offline_access"],
  extraScopesToConsent: concentScopes,
};

export const amiAdminApiTokenRequest = {
  scopes: concentScopes,
};
