import { IUIElementState, IUser, UIElement } from "Common/Interfaces";
import { useGetMyDetailsQuery } from "Store/Services/Users";
import { useState } from "react";
import { UIElementStateDefault } from "../Constants";

export const useViewProfile = () => {
  const [uiElementState, setUiElementState] = useState<IUIElementState<IUser>>(UIElementStateDefault);

  const handleContextMenuButton = (event: React.MouseEvent<HTMLButtonElement>) =>
    setUiElementState({
      element: event.currentTarget,
      cellDetails: null,
      openElement: UIElement.ContextMenu,
      isSuccess: false,
      message: "",
      dataRow: null,
    });

  const { data: userDetails, isLoading: isUserDetailsLoading, isError: isUserDetailsError } = useGetMyDetailsQuery({});

  return {
    uiElementState,
    setUiElementState,
    handleContextMenuButton,
    userDetails,
    isUserDetailsLoading,
    isUserDetailsError,
  };
};
