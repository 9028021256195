import { useGetMyDetailsQuery, useUpdateMyDetailsMutation } from "Store/Services/Users";
import { INITIAL_FORM_VALUES } from "../Constants";
import { IFormValues } from "../Interfaces";

export const useEditProfile = () => {
  const { data: userDetails, isLoading: isUserDetailsLoading, isError: isUserDetailsError } = useGetMyDetailsQuery({});

  const getInitialFormValues = (): IFormValues => {
    if (userDetails) {
      return {
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        mobilePhone: userDetails.mobile ?? "",
        workPhone: userDetails.work ?? "",
        jobTitle: userDetails.jobTitle ?? "",
      };
    }
    return INITIAL_FORM_VALUES;
  };

  const [updateMyDetails] = useUpdateMyDetailsMutation();
  
  return {
    getInitialFormValues,
    isUserDetailsLoading,
    isUserDetailsError,
    updateMyDetails,
  };
};
