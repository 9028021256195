import { useTheme } from "@mui/material";
import { $Box, $Divider, $Stack } from "Common/Components";
import { SideBarAppButtonMobile } from "../SideBarAppButton";
import { useGetUserApplicationsQuery } from "Store/Services/Users";
import { SideBarButton } from "../SideBarButton/SideBarButton";
import { HomeOutlined } from "Icons/Icons";
import { ActionButtonMobile } from "../ActionButton";
interface ISideBar {
  permissions: string[];
  handleCollapse?: () => void;
  isCollapsed?: boolean;
  setIsCollapsed?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SideBar = ({ permissions, handleCollapse, isCollapsed, setIsCollapsed }: ISideBar) => {
  const theme = useTheme();
  const { data } = useGetUserApplicationsQuery({
    isDeleted: false,
    limit: 10,
    offset: 0,
    orderBy: "name",
    search: "",
    sortBy: "1",
  });
  const { data: applications } = data ?? {};
  return (
    <$Box
      sx={{
        width: "210px",
        flexShrink: { xs: 1, md: 0 },
        flexGrow: { xs: 1, md: 0 },
        marginLeft: isCollapsed ? "-180px" : "0px",
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: theme.palette.grey[300],
        position: "relative",
      }}
    >
      <$Stack
        sx={{
          paddingTop: 2,
          visibility: isCollapsed ? "hidden" : "visible",
        }}
      >
        <$Box pt={3}>
          <SideBarButton
            key={1}
            icon={<HomeOutlined />}
            name={"Home"}
            isActive={true}
            to={"/"}
            hasBorder={false}
            tooltipTitle={"AMI Admin Central"}
          />
          <ActionButtonMobile />
        </$Box>
        <$Divider
          flexItem
          variant="middle"
          sx={{
            my: 3,
          }}
        />
        <$Stack
          flex={1}
          sx={{
            marginLeft: "10px",
          }}
        >
          {applications?.map(({ id, displayName, applicationURL, logoURL }) => (
            <SideBarAppButtonMobile
              logoURL={logoURL ?? ""}
              key={id}
              applicationId={id.toString()}
              name={displayName}
              redirectURL={applicationURL ?? ""}
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.preventDefault();
                window.open(applicationURL, "_blank");
              }}
            />
          ))}
        </$Stack>
      </$Stack>
    </$Box>
  );
};
