import { styled } from "@mui/material";
import { $ListItemButton, $ListItemIcon, $ListItemText, $Tooltip } from "Common/Components";
import { MouseEventHandler } from "react";
import { Link, To } from "react-router-dom";

const StyledListItemButton = styled($ListItemButton)<{
  to: To;
  component?: typeof Link;
  hasborder: string;
}>(({ theme, hasborder }) => ({
  paddingLeft: "20px",
  position: "relative",
  borderTop: hasborder === "true" ? "1px solid" : "none",
  borderColor: theme.palette.divider,
  "& .MuiListItemText-root": {
    marginLeft: "10px",
    color: theme.palette.primary.main,
  },
  "& .MuiListItemIcon-root": {
    color: theme.palette.primary.main,
    svg: {
      width: "16px",
      height: "16px",
    },
  },
  ":hover": {
    background: theme.palette.grey[100],
  },
  "&.Mui-selected": {
    background: theme.palette.grey[100],
    ":before": {
      content: '""',
      left: "0",
      position: "absolute",
      width: "5px",
      height: "100%",
      backgroundColor: theme.palette.secondary.main,
    },
    ":hover": {
      background: theme.palette.grey[100],
    },
    "& .MuiListItemText-root": {
      "& .MuiListItemText-primary": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
}));

interface SideBarButtonProps {
  name: string;
  icon?: JSX.Element;
  isActive: boolean;
  isDisabled?: boolean;
  to: To;
  hasBorder: boolean;
  tooltipTitle?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const SideBarButton: React.FC<SideBarButtonProps> = ({
  icon,
  name,
  isActive,
  isDisabled,
  to,
  hasBorder,
  tooltipTitle,
  onClick,
}) => {
  return (
    <$Tooltip title={tooltipTitle} arrow placement="right">
      <StyledListItemButton
        selected={isActive}
        disabled={isDisabled}
        hasborder={hasBorder.toString()}
        component={Link}
        to={to}
        onClick={onClick}
      >
        {icon && <$ListItemIcon>{icon}</$ListItemIcon>}
        <$ListItemText primary={name} />
      </StyledListItemButton>
    </$Tooltip>
  );
};
