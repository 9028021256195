import { $Snackbar, FormDrawer } from "Common/Components";
import { DrawerEventType } from "Common/Constants";
import { EditProfile, ViewProfile } from "./Containers";
import { IDrawerState } from "./Interfaces";
import { ISnackbarState } from "Common/Interfaces";

interface IMyProfile {
  drawer: IDrawerState;
  setDrawer: React.Dispatch<React.SetStateAction<IDrawerState>>;
  snackBar: ISnackbarState;
  setSnackBar: React.Dispatch<React.SetStateAction<ISnackbarState>>;
  handleEditProfileButton: () => void;
}

export const MyProfile: React.FC<IMyProfile> = (props) => {
  const { drawer, snackBar, setDrawer, setSnackBar, handleEditProfileButton } = props;
  return (
    <>
      <FormDrawer
        isOpen={drawer.isOpen}
        drawerStatusEvent={(event) => {
          if (event === DrawerEventType.Close) {
            setDrawer((prevState) => ({ ...prevState, isOpen: false }));
          }
        }}
        drawerTitle={drawer.title}
      >
        <>
          {drawer.type === "EDIT" && (
            <EditProfile
              onClose={() => {
                setDrawer((prevState) => ({ ...prevState, isOpen: false }));
              }}
              onSubmitSnackBar={(state) => {
                setSnackBar(state);
              }}
            />
          )}

          {drawer.type === "VIEW" && (
            <ViewProfile
              onClose={() => {
                setDrawer((prevState) => ({ ...prevState, isOpen: false }));
              }}
              handleEditProfileButton={handleEditProfileButton}
            />
          )}
        </>
      </FormDrawer>
      <$Snackbar
        open={snackBar.isOpen}
        onClose={() => {
          setSnackBar((prevState) => ({ ...prevState, isOpen: false }));
        }}
        isSuccess={snackBar.isSuccess}
        message={snackBar.message}
      />
    </>
  );
};
