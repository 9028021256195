import { Box, ClickAwayListener, Fade, Paper, Popper } from "@mui/material";

interface IHeaderPopup {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: (event: Event | React.SyntheticEvent) => void;
  children?: React.ReactNode;
  minWidth?: number;
}
export const HeaderPopup: React.FC<IHeaderPopup> = (props) => {
  const { anchorEl, open, handleClose, children, minWidth } = props;
  return (
    <Popper
      placement={"bottom-end"}
      open={open}
      anchorEl={anchorEl}
      transition
      disablePortal
      popperOptions={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 9],
            },
          },
        ],
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            sx={(theme) => ({
              boxShadow: "theme.customShadows.z1",
              width: "100%",
              minWidth: minWidth ?? 285,
              maxWidth: 420,
              [theme.breakpoints.down("sm")]: {
                maxWidth: "100%",
                width: "100vw",
              },
              // borderRadius: "6px",
            })}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Box>{children}</Box>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};
