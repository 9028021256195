// cookieUtils.ts

// Function to get the main domain dynamically
function getMainDomain(): string {
  var domain = process.env.REACT_APP_DOMAIN_NAME as string;
  return domain;
}

// Function to set a cookie
function setCookie(name: string, value: string, days: number): void {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  const domain = getMainDomain();
  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/; domain=${domain}`;
  console.log(
    `Cookie set: ${name}=${value}; expires=${expires.toUTCString()}; path=/; domain=${domain}`
  );
}

// Function to get a cookie by name
function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift() || null;
  return null;
}

// Function to remove a cookie
function removeCookie(name: string): void {
  const expires = new Date(0); // Set expiration date to the past
  const domain = getMainDomain();
  document.cookie = `${name}=; expires=${expires.toUTCString()}; path=/; domain=${domain}`;
  console.log(
    `Cookie removed: ${name}; expires=${expires.toUTCString()}; path=/; domain=${domain}`
  );
}

// Export the functions
export { setCookie, getCookie, removeCookie };	
