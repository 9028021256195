import { Box, Typography, useTheme } from "@mui/material";

interface IDeleteConfirmationContent {
  title: string;
  value: string;
  hasQuestionMark?: boolean;
}

export const DeleteConfirmationContent = (props: IDeleteConfirmationContent) => {
  const { title, value, hasQuestionMark = true } = props;
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography textAlign={"center"}>
        {title}
        <Typography display="inline" fontWeight={theme.typography.fontWeightMedium}>
          {` ${value}`}
        </Typography>{" "}
        {hasQuestionMark && " ?"}
      </Typography>
    </Box>
  );
};
