import {
  $Box,
  $Button,
  $Dialog,
  $DialogActions,
  $DialogContent,
  $DialogContentText,
  $DialogTitle,
  $LoadingButton,
} from "Common/Components";
import { isValidElement } from "react";

export interface IConfirmationDialog {
  isOpen: boolean;
  title: string;
  message: string | JSX.Element;
  onClose?: () => void;
  onClickAction: () => void;
  okBtnText?: string;
}

export const ConfirmationDialog: React.FC<IConfirmationDialog> = (props) => {
  const { isOpen, title, message, onClose, onClickAction, okBtnText } = props;

  return (
    <$Dialog open={isOpen} onClose={() => onClose && onClose()} maxWidth="sm" fullWidth>
      <$DialogTitle
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          color: "info.main",
          pb: 3,
          pt: 3,
          fontSize: "1.15rem",
          fontWeight: 400,
        }}
      >
        {title}
      </$DialogTitle>
    
       
            <$DialogContent sx={{ px: 4 }}>
              {isValidElement(message) ? (
                message
              ) : (
                <$DialogContentText
                  sx={{
                    color: "text.primary",
                    pb: 1,
                    minWidth: "400px",
                    textAlign: "center",
                  }}
                >
                  {message}
                </$DialogContentText>
              )}
            </$DialogContent>
            <$DialogActions sx={{ justifyContent: "center", py: 3 }}>
              {Boolean(onClose) && (
                <$Button variant="text" color="primary" onClick={() => onClose && onClose()} sx={{ px: 4 }}>
                  Cancel
                </$Button>
              )}
              <$LoadingButton
                variant="contained"
                onClick={onClickAction}
                // loading={isSubmitting}
                // loadingPosition={isSubmitting ? "start" : undefined}
                // startIcon={isSubmitting ? <$Box sx={{ px: 1 }}> </$Box> : undefined}
                sx={{ px: 4 }}
              >
                <span>{okBtnText ?? "Ok"}</span>
              </$LoadingButton>
            </$DialogActions>
       
      
    </$Dialog>
  );
};
