import { Avatar, ButtonBase, alpha, useTheme } from "@mui/material";
import {
  $Box,
  $Grid,
  $IconButton,
  $ListItemButton,
  $ListItemText,
  $Stack,
  $Typography,
  ApplicationAvatar,
  DataGridLoading,
} from "Common/Components";
import { HomeOutlined, MoreMenuOutlined } from "Icons/Icons";
import { useGetUserApplicationsQuery } from "Store/Services/Users";
import { HeaderPopup } from "../Components";
import { useHeaderPopup } from "../Hooks";

export const AppsMenu = () => {
  const theme = useTheme();

  const { anchorRef, open, handleToggle, handleClose } = useHeaderPopup();

  const handleHome = (event: React.MouseEvent<HTMLButtonElement>) => {
    var url = process.env.REACT_APP_LANDING_PAGE_URL;
    window.location.href = url ? url : "";
    handleClose(event);
  };

  const { data, isFetching } = useGetUserApplicationsQuery({
    isDeleted: false,
    limit: 50,
    offset: 0,
    orderBy: "name",
    search: "",
    sortBy: "1",
  });

  const { data: apps } = data ?? {};

  return (
    <>
      <ButtonBase
        sx={{
          bgcolor: "transparent",
          borderRadius: "50%",
          "&:hover": { bgcolor: alpha(theme.palette.primary.light, 0.5) },
        }}
        aria-label="open setting"
        ref={anchorRef}
        aria-controls={open ? "apps-menu" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <$Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar alt="profile user" sx={{ width: 32, height: 32, bgcolor: "transparent", color: "common.white" }}>
            <MoreMenuOutlined sx={{ fontSize: "1.2rem" }} />
          </Avatar>
        </$Stack>
      </ButtonBase>

      <HeaderPopup anchorEl={anchorRef.current} open={open} handleClose={handleClose}>
        <$Box p={2}>
          <$Grid container direction="row" justifyContent="space-between" alignItems="center">
            <$Grid item>
              <$Typography variant="subtitle1" ml={1}>
                Apps
              </$Typography>
            </$Grid>
            <$Grid item>
              <$IconButton
                size="small"
                sx={{
                  padding: 1,
                }}
                onClick={(event) => {
                  handleHome(event);
                }}
              >
                <HomeOutlined color="primary" sx={{ fontSize: "1.2rem" }} />
              </$IconButton>
            </$Grid>
          </$Grid>

          <DataGridLoading isLoading={isFetching}>
            <$Grid container direction="row">
              <$Stack pt={2} flex={1}>
                {apps?.map(({ id, applicationURL, displayName, logoURL }) => (
                  <$ListItemButton
                    sx={{
                      color: theme.colors.alpha.black[100],
                    }}
                    key={id}
                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                      e.preventDefault();
                      window.open(applicationURL, "_blank");
                    }}
                  >
                    <ApplicationAvatar logoLink={logoURL} applicationId={id.toString()} onlyAvatar />
                    <$ListItemText primary={displayName} color={theme.palette.text.primary} />
                  </$ListItemButton>
                ))}
              </$Stack>
            </$Grid>
          </DataGridLoading>
        </$Box>
      </HeaderPopup>
    </>
  );
};
