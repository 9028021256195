import { $Card, $CardActionArea, $CardHeader, $CardMedia, $Grid } from "Common/Components";
import { useTheme } from "@mui/material/styles";

interface IWidgetTile {
  tileTitle: string;
  tileImage: any;
  linkURL: string;
}

export const WidgetTile: React.FC<IWidgetTile> = (props) => {
  const { tileTitle, tileImage, linkURL } = props;

  const theme = useTheme();

  return (
    <$Grid item zeroMinWidth xs={12} sm={6} lg={2}>
      <$Card
        elevation={0}
        sx={{
          borderRadius: "0px",
          boxShadow: "none",
          transition: "transform 0.3s ease-in-out",
          height: "100%",
          "&:hover": {
            ".MuiCardMedia-img": {
              transform: "scale3d(1.1, 1.1, 1)",
            },
          },
        }}
      >
        <$CardActionArea href={linkURL} target="_blank" rel="noreferrer">
          <$CardHeader
            sx={{
              position: "absolute",
              left: "0",
              right: "0",
              top: "20px",
              py: 0.3,
              zIndex: 999,
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              ".MuiCardHeader-title": {
                textTransform: "uppercase !important",
                fontSize: "0.75rem !important",
                fontWeight: "600 !important",
              },
            }}
            title={tileTitle}
          />
          <$CardMedia
            component="img"
            height="100%"
            width="100%"
            image={tileImage}
            alt="preview image name"
            sx={{
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </$CardActionArea>
      </$Card>
    </$Grid>
  );
};
