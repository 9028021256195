import { IConfiguration } from "BugTracker/Common/Interfaces";
import { documentBaseAPI } from "Store/DocumentBaseAPI";

const controller = "configurations";
export const configurationsAPI = documentBaseAPI.injectEndpoints({
  endpoints: (build) => ({
    getAllowedFileTypes: build.query<IConfiguration, {}>({
      query: () => `${controller}/allowedfiletypes`,
      transformResponse: (response: IConfiguration, meta, arg) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllowedFileTypesQuery } = configurationsAPI;
