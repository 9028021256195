import { CloseOutlined } from "@mui/icons-material";
import { Drawer } from "@mui/material";
import { Stack } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { $Box, $Typography, $IconButton } from "./MUI";
import { DrawerEventType } from "Common/Constants";

interface IFormDrawer {
  isOpen: boolean;
  drawerStatusEvent: (event: DrawerEventType) => void;
  children: JSX.Element;
  drawerTitle: string;
  width?: string;
  className?: string;
}

export const FormDrawer: React.FC<IFormDrawer> = (props) => {
  const { isOpen, drawerStatusEvent, children, drawerTitle, width = "600px", className } = props;
  const theme = useTheme();

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      sx={{
        flexShrink: { md: 0 },
        zIndex: 1300,

        ".MuiDrawer-paper": {
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
          [theme.breakpoints.up("md")]: {
            width,
          },
        },
      }}
    >
      <$Box className={className} sx={{ width: "100%", height: "100%" }} display="flex" flexDirection="column">
        {/* header */}
        <$Box
          sx={{
            px: 2,
            pl: 4,
            py: "0.11rem",
            borderBottom: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: theme.palette.secondary.main,
            minHeight: "48px",
          }}
          display="flex"
          flex={0}
        >
          <Stack
            spacing={2}
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <$Typography variant="subtitle1" color={theme.palette.common.white}>
              {drawerTitle}
            </$Typography>
            <$IconButton
              size="small"
              onClick={() => drawerStatusEvent(DrawerEventType.Close)}
              sx={{ color: theme.palette.grey[200] }}
            >
              <CloseOutlined />
            </$IconButton>
          </Stack>
        </$Box>
        {/* content */}
        {children}
      </$Box>
    </Drawer>
  );
};
