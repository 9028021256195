import { $Box, $Button, $Typography } from "./MUI";

import { useTheme } from "@mui/material/styles";
import UserInactiveImage from "../StateMessagesSvg/UserInactiveImage";
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "authConfig";
import signalRService from "SignalR/signalRInstance";
import { useEffect } from "react";

interface IUserInactiveComponent {
  headerMessage: string;
  detailMessage: string;
  hideButton?: boolean;
}

export const UserInactiveComponent: React.FC<IUserInactiveComponent> = (props) => {
  const { headerMessage, detailMessage, hideButton } = props;

  const theme = useTheme();
  const { instance } = useMsal();

  useEffect(() => {
    // Set up the logout response callback to handle logout with accountId
    signalRService.setLogoutResponseCallback(handleLogoutResponse);
  }, []);

  const handleLogoutResponse = (accountId: string) => {
    // Perform the logout using the accountId, if necessary
    handleSSOLogout(accountId);
  };

  const handleSSOLogout = async (accountId: string) => {
    let localAccountId = instance.getActiveAccount()?.localAccountId;

    if (localAccountId === undefined || localAccountId === accountId) {
      // Proceed with logout if the accountId matches
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
      });
    }
  };

  const handleLogout = async () => {
    // Retrieve the local account ID
    let localAccountId = instance.getActiveAccount()?.localAccountId;
    console.log("calling handleLogout with accountId:", localAccountId);

    if (localAccountId) {
      try {
        // Ensure the SignalR connection is established
        if (!signalRService.isConnected()) {
          await signalRService.startConnection();
        }
        // Send the logout request and wait for it to complete
        await signalRService.sendLogoutRequest(localAccountId);

        // Only call logoutRedirect after the logout request is successfully sent
        instance.logoutRedirect({
          account: instance.getActiveAccount(),
          postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        });
      } catch (error) {
        console.error("Error handling logout:", error);
        // Handle any errors that occur during connection or logout request
      }
    } else {
      console.error("No active account found for logout.");
    }
  };

  return (
    <>
      <$Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <$Box>
          <UserInactiveImage />
        </$Box>
        <$Typography variant="h4" color="primary.dark" fontWeight={400} data-testid="inactive-header-message">
          {headerMessage}
        </$Typography>
        <$Typography
          variant="caption"
          color={theme.palette.text.secondary}
          textAlign="center"
          data-testid="inactive-detail-message"
        >
          {detailMessage}
        </$Typography>
        {!hideButton && (
          <$Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ mt: 4, px: 4 }}
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              handleLogout().catch((error) => {
                console.error("Error handling logout:", error);
              })
            }
            data-testid="inactive-logout"
          >
            Log out
          </$Button>
        )}
      </$Box>
    </>
  );
};
