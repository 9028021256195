import { Tooltip, IconButton, SxProps, Theme, useTheme } from "@mui/material";
import { useMatchDownMd } from "Common/Hooks";
import { HorizontalLineOutlined, KeyArrowLeftOutlined, KeyArrowRightOutlined } from "Icons/Icons";
import { useState } from "react";

interface ICollapseButton {
  isCollapsed: boolean;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

export const CollapseButton = ({ isCollapsed, onClick, sx }: ICollapseButton) => {
  const theme = useTheme();
  const [isHover, setIsHover] = useState<boolean>(false);
  const { matchDownMd } = useMatchDownMd();

  console.log(isHover);
  return (
    <Tooltip title={isCollapsed ? "Expand Sidebar" : " Collapse Sidebar"} arrow placement="right">
      <IconButton
        size="small"
        sx={{
          "& .MuiSvgIcon-root": {
            fontSize: "1rem",
            color: theme.colors.alpha.black[matchDownMd && !isCollapsed ? 70 : 50],
          },

          zIndex: theme.zIndex.drawer + 1000,
          "&:hover": {
            background: "none",
          },
          position: "absolute",
          ...sx,
        }}
        onClick={() => {
          onClick && onClick();
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isCollapsed ? (
          <KeyArrowRightOutlined />
        ) : matchDownMd ? (
          <KeyArrowLeftOutlined />
        ) : isHover ? (
          <KeyArrowLeftOutlined />
        ) : (
          <HorizontalLineOutlined />
        )}
      </IconButton>
    </Tooltip>
  );
};
