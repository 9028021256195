import { useCreateBugMutation } from "BugTracker/Store/Services/Admin/BugTracker";
import { useState } from "react";
import { useGetAllowedFileTypesQuery } from "BugTracker/Store/Services/Configurations";
import { DEFAULT_DIVISION_SEARCH_QUERY } from "../Constants";
import { IDivisionListRequest } from "Common/Interfaces";

export const UseBugTracker = () => {
  const [createBug] = useCreateBugMutation();
  const [divisionsQuery, setDivisionsQuery] = useState<IDivisionListRequest>({
    ...DEFAULT_DIVISION_SEARCH_QUERY,
    limit: 250,
  });

  const { data: fileTypeQuery } = useGetAllowedFileTypesQuery({});
  const allowedFileTypes: string[] = fileTypeQuery?.value.split(",").filter((val: string) => val != "mp4") ?? [];

  return {
    createBug,
    divisionsQuery,
    setDivisionsQuery,
    allowedFileTypes,
  };
};
