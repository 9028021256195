import { alpha } from "@mui/material/styles";

const colors = ["#8d6999", "#FF8C8C", "#2A88BC", "#489D9D", "#124460"];

export const getColorById = (id: string) => {
  const numericId = parseInt(id, 10);
  const colorIndex = numericId % colors.length;
  return colors[colorIndex];
};

export const matchStringToColor = (string: string) => {
  try {
    let hash = 1;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 4) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 3)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return alpha(color, 0.8);
  } catch (error) {
    return "#";
  }
};
