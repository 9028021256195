const Logo = () => {
  return (
    <>
      <svg width="67" height="25" viewBox="0 0 67 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.4888 22.1702H23.5007L16.0068 10.6324L19.3772 5.33722L32.1483 25.0035L23.4888 22.1702Z" fill="#F83437" />
        <path
          d="M12.8065 15.8433L12.8255 16.0759L30.3229 14.6855L8.93255 21.7484L12.42 16.2675L12.5276 16.0992L12.8184 16.077L12.7995 15.8433L12.6848 15.8527L19.3772 5.33722L15.9109 0L0 25L8.56962 22.1702L8.68783 22.1316L8.73394 22.0603L32.2404 14.2987L12.8065 15.8433Z"
          fill="#002948"
        />
        <path
          d="M46.1313 6.62128H43.7409C43.652 6.62114 43.565 6.64688 43.4908 6.6953C43.4166 6.74371 43.3584 6.81265 43.3236 6.89351L37.6929 20.0087C37.6639 20.0766 37.6523 20.1506 37.6592 20.2241C37.6661 20.2975 37.6912 20.3682 37.7324 20.4297C37.7736 20.4912 37.8295 20.5417 37.8952 20.5767C37.9609 20.6117 38.0344 20.6301 38.109 20.6303H39.8315C39.9221 20.6303 40.0106 20.6034 40.0855 20.5529C40.1603 20.5025 40.2181 20.4309 40.2511 20.3475L41.4451 17.3529C41.4785 17.2695 41.5365 17.198 41.6116 17.1476C41.6866 17.0972 41.7752 17.0702 41.866 17.0702H43.7055C43.7655 17.0702 43.825 17.082 43.8804 17.1049C43.9358 17.1278 43.986 17.1614 44.0282 17.2037C44.0703 17.246 44.1035 17.2962 44.1258 17.3513C44.1482 17.4064 44.1592 17.4653 44.1582 17.5247L44.1145 20.1757C44.1136 20.235 44.1245 20.2938 44.1468 20.3489C44.169 20.4039 44.2021 20.454 44.2442 20.4963C44.2862 20.5386 44.3363 20.5722 44.3915 20.5952C44.4468 20.6182 44.5061 20.6301 44.5661 20.6303H46.2672C46.3269 20.6301 46.3859 20.6183 46.441 20.5955C46.496 20.5727 46.5459 20.5394 46.5879 20.4975C46.6299 20.4556 46.663 20.4059 46.6855 20.3513C46.708 20.2967 46.7193 20.2382 46.7188 20.1793L46.5782 7.0641C46.5772 6.94714 46.5299 6.83521 46.4463 6.7524C46.3627 6.66958 46.2496 6.6225 46.1313 6.62128ZM44.4502 9.77592C44.4376 10.0641 44.4246 10.3679 44.4112 10.6873L44.3025 14.488C44.2991 14.6043 44.2501 14.7146 44.1658 14.7958C44.0815 14.8769 43.9685 14.9224 43.8509 14.9227H43.0316C42.9591 14.9226 42.8877 14.9053 42.8234 14.8723C42.759 14.8392 42.7037 14.7913 42.6621 14.7326C42.6205 14.6739 42.5937 14.6062 42.5842 14.5352C42.5746 14.4641 42.5825 14.3918 42.6072 14.3245L43.9525 10.6382C44.0625 10.3204 44.1606 10.019 44.2469 9.7362C44.3348 9.45575 44.4027 9.16958 44.4502 8.87977H44.5082C44.4774 9.18823 44.4573 9.48616 44.4502 9.77592Z"
          fill="#002948"
        />
        <path
          d="M61.7869 6.67853H59.1353C59.0475 6.67868 58.9616 6.70405 58.8882 6.75156C58.8147 6.79906 58.7568 6.86665 58.7215 6.94609L54.5342 16.3458H54.4763L54.1902 7.11551C54.1868 6.99937 54.1378 6.88912 54.0534 6.80818C53.9691 6.72724 53.8562 6.68198 53.7386 6.68203H51.2561C51.153 6.68201 51.053 6.71673 50.9726 6.78046C50.8922 6.84419 50.8362 6.93312 50.8139 7.03255L48.0263 20.0963C48.0125 20.1614 48.0134 20.2287 48.0292 20.2934C48.045 20.3581 48.0751 20.4185 48.1175 20.4702C48.1598 20.5219 48.2133 20.5636 48.274 20.5923C48.3347 20.621 48.4012 20.636 48.4685 20.6361H49.8812C49.9843 20.6363 50.0844 20.6017 50.1648 20.5379C50.2452 20.4742 50.3012 20.3851 50.3233 20.2856L51.8022 13.35C51.8531 13.1397 51.8992 12.9236 51.937 12.7016C51.9748 12.4796 52.015 12.2459 52.0552 12.0005C52.0954 11.7552 52.1356 11.5051 52.1805 11.2376H52.2384L52.5056 20.2038C52.5093 20.3198 52.5585 20.4299 52.6427 20.5108C52.727 20.5917 52.8397 20.637 52.9572 20.6373H54.3758C54.4627 20.6372 54.5478 20.6124 54.6207 20.5657C54.6937 20.5191 54.7515 20.4527 54.7872 20.3744L58.9911 11.1733H59.0301C58.9851 11.3579 58.9379 11.5414 58.8894 11.7225C58.8409 11.9036 58.7936 12.0835 58.7452 12.2611C58.6967 12.4387 58.6529 12.6116 58.6151 12.7962L57.044 20.0963C57.0301 20.1614 57.0311 20.2287 57.0469 20.2934C57.0626 20.3581 57.0928 20.4185 57.1351 20.4702C57.1775 20.5219 57.231 20.5636 57.2917 20.5923C57.3524 20.621 57.4188 20.636 57.4861 20.6361H58.9769C59.0799 20.6359 59.1797 20.6011 59.2601 20.5374C59.3404 20.4737 59.3965 20.3849 59.419 20.2856L62.229 7.21832C62.2432 7.15321 62.2423 7.0858 62.2267 7.02103C62.211 6.95626 62.1809 6.89578 62.1385 6.84403C62.0961 6.79227 62.0425 6.75054 61.9817 6.7219C61.9208 6.69327 61.8543 6.67845 61.7869 6.67853Z"
          fill="#002948"
        />
        <path
          d="M66.5466 6.67853H65.0192C64.9162 6.6785 64.8162 6.71322 64.7358 6.77695C64.6554 6.84068 64.5994 6.92961 64.5771 7.02904L61.7659 20.0963C61.7518 20.1614 61.7526 20.2288 61.7682 20.2936C61.7839 20.3584 61.8141 20.4188 61.8565 20.4706C61.8989 20.5224 61.9524 20.5641 62.0133 20.5927C62.0741 20.6214 62.1406 20.6362 62.208 20.6361H63.7543C63.8574 20.6363 63.9575 20.6017 64.0379 20.5379C64.1184 20.4742 64.1743 20.3851 64.1964 20.2856L66.9887 7.21832C67.0028 7.15321 67.002 7.0858 66.9864 7.02103C66.9707 6.95626 66.9406 6.89578 66.8982 6.84403C66.8558 6.79227 66.8022 6.75054 66.7414 6.7219C66.6805 6.69327 66.614 6.67845 66.5466 6.67853Z"
          fill="#002948"
        />
      </svg>
    </>
  );
};

export default Logo;
