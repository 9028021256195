import { MoreVert } from "@mui/icons-material";
import { $Menu, $MenuItem, $ListItemIcon, $ListItemText, $Divider, $IconButton } from "../MUI";
import { IContextMenuItem } from "Common/Interfaces";

interface IContextMenu<T> {
  anchorEl: HTMLElement | null;
  onCloseEvent: (reason?: "escapeKeyDown" | "backdropClick" | "tabKeyDown") => void;
  menuItems: IContextMenuItem<T>[];
  moreButtonClickEvent: (event: React.MouseEvent<HTMLButtonElement>) => void;
  hideMoreVert?: boolean;
  rightOrigin?: true;
}

export const ContextMenu = <T extends {}>(props: IContextMenu<T>) => {
  const { anchorEl, onCloseEvent, menuItems, moreButtonClickEvent, hideMoreVert, rightOrigin } = props;
  const isOpen = Boolean(anchorEl);

  return (
    <>
      {!hideMoreVert && (
        <$IconButton
          size="small"
          className="com-more-action"
          id="cd-action-button"
          aria-controls={isOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? "true" : undefined}
          onClick={moreButtonClickEvent}
          sx={(theme) => ({
            backgroundColor: isOpen ? theme.colors.alpha.black[10] : "none",
          })}
        >
          <MoreVert fontSize="small" />
        </$IconButton>
      )}

      {menuItems.length > 0 && isOpen && (
        <$Menu
          id="cd-action-menu"
          anchorEl={anchorEl}
          open={isOpen}
          onClose={(e, reason) => onCloseEvent(reason)}
          MenuListProps={{
            "aria-labelledby": "cd-action-button",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: rightOrigin ? "right" : "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: rightOrigin ? "left" : "right",
          }}
        >
          {menuItems.map(({ event, icon, itemText, showDivider, onMenuItemClickEvent, menuItemSX }) => [
            showDivider && menuItems.length > 1 && <$Divider sx={{ my: 0.5 }} />,
            <$MenuItem
              onClick={() => {
                onMenuItemClickEvent(event);
                onCloseEvent();
              }}
              sx={menuItemSX}
            >
              <$ListItemIcon
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: "1rem",
                  },
                }}
              >
                {icon}
              </$ListItemIcon>
              <$ListItemText primary={itemText} />
            </$MenuItem>,
          ])}
        </$Menu>
      )}
    </>
  );
};
