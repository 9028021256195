import { IBug } from "BugTracker/Common/Interfaces";
import { IBugUIElementState } from "../Interfaces";

export const DEFAULT_BUG_UI_ELEMENT_STATE: IBugUIElementState<IBug> = {
  element: null,
  cellDetails: null,
  openElement: null,
  message: "",
  isSuccess: false,
  dataRow: null,
};
