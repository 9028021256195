import { MsalProvider } from "@azure/msal-react";
import App from "App";
import { Provider as ReduxProvider } from "react-redux";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { amiAdminApiTokenRequest, msalConfig } from "./authConfig";
import { store } from "Store";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { LoginPage } from "Common/Components";
import ThemeCustomization from "Theme/ThemeProvider";
import axios, { AxiosRequestConfig } from "axios";
import { jwtDecode } from "jwt-decode";
import { removeCookie } from "./Cookies/CookieUtils";
import { useEffect } from "react";
import signalRService from "SignalR/signalRInstance";

export const AppLanding = () => {
  const msalInstance = new PublicClientApplication(msalConfig);

  // Get the timeout value from the environment configuratio
  let timeout = process.env.REACT_APP_SESSION_TIMEOUT
    ? parseInt(process.env.REACT_APP_SESSION_TIMEOUT, 10)
    : 43200000;

    useEffect(() => {
      // Set up the logout response callback to handle logout with accountId
      signalRService.setLogoutResponseCallback(handleLogoutResponse);
    }, []);
  
    const handleLogoutResponse = (accountId: string) => {
      // Perform the logout using the accountId, if necessary
      handleSSOLogout(accountId);
    };
  
    const handleSSOLogout = async (accountId: string) => {
      let localAccountId = msalInstance.getActiveAccount()?.localAccountId;
      console.log("Logged user accountId:", accountId);
  
      if (localAccountId === undefined || localAccountId === accountId) {
  
        localStorage.setItem('isUserLoggedOut', 'true');
        removeCookie('currentuserid');
  
        // Proceed with logout if the accountId matches
        msalInstance.logoutRedirect({
          account: msalInstance.getActiveAccount(),
          postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        });
      } else {
        console.error("Account ID mismatch or account not found. AccountId: ", localAccountId);
      }
    };

  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
  msalInstance.addEventCallback((event: any) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload.account
    ) {
      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS
      ) {
        // Set session expiration timeout
        setTimeout(() => {
          msalInstance.logoutRedirect();
        }, timeout);
        console.log("Session timeout set to: ", timeout);
      }

      msalInstance.setActiveAccount(event.payload.account);

      if (event.eventType === EventType.LOGIN_SUCCESS) {
        localStorage.removeItem("isUserLoggedOut");

        msalInstance
          .acquireTokenSilent(amiAdminApiTokenRequest)
          .then((response) => {
            const config: AxiosRequestConfig<any> = {
              headers: {
                "content-type": "application/json",
                authorization: `Bearer ${response.accessToken}`,
                "Ocp-Apim-Subscription-Key":
                  process.env.REACT_APP_SUBSCRIPTION_KEY,
              },
            };
            const url = `${process.env.REACT_APP_API_BASE_URL}activities/login`;
            axios
              .post(
                url,
                {
                  email: (jwtDecode(response.accessToken) as any)?.email,
                  application: "AMI Home",
                },
                config
              )
              .then((response) => console.log("success", response))
              .catch((error) => {
                console.error(error);
              });
          });
      }
    }
  });

  return (
    <MsalProvider instance={msalInstance}>
      <ReduxProvider store={store}>
        <ThemeCustomization>
          <AuthenticatedTemplate>
            <App />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <LoginPage />
          </UnauthenticatedTemplate>
        </ThemeCustomization>
      </ReduxProvider>
    </MsalProvider>
  );
};
