import { PhoneNumberValidator } from "Common/Validators";
import * as Yup from "yup";

export const EditProfileSchema = Yup.object().shape({
  firstName: Yup.string().validUnicode().trim().required("Please enter the first name of the user."),
  lastName: Yup.string().validUnicode().trim().required("Please enter the last name of the user."),
  jobTitle: Yup.string().validUnicode().trim().nullable(),
  mobilePhone: Yup.string().trim().validUnicode().matches(PhoneNumberValidator, "Please enter a valid phone number."),
  workPhone: Yup.string().validUnicode().matches(PhoneNumberValidator, "Please enter a valid phone number."),
});
