import { Formik } from "formik";
import { IFormValues } from "./Interfaces";
import { EditProfileSchema } from "./Validations";
import { $Grid, $PhoneNumberInput, $TextField, DataGridLoading, FormContent, FormFooter } from "Common/Components";
import { DrawerEventType } from "Common/Constants";
import { useEditProfile } from "./Hooks";
import { Edit } from "./Repository";
import { ISnackbarState } from "Common/Interfaces";

interface IEditProfile {
  onClose: () => void;
  onSubmitSnackBar: (state: ISnackbarState) => void;
}

export const EditProfile: React.FC<IEditProfile> = (props) => {
  const { onClose, onSubmitSnackBar } = props;
  const { getInitialFormValues, isUserDetailsLoading, isUserDetailsError, updateMyDetails } = useEditProfile();
  return (
    <>
      <Formik<IFormValues>
        enableReinitialize
        initialValues={getInitialFormValues()}
        validationSchema={EditProfileSchema}
        validateOnMount
        validateOnChange
        validateOnBlur
        onSubmit={async (values) => {
          try {
            const request = Edit.call(values);
            const response: any = await updateMyDetails(request);

            if (response.hasOwnProperty("error")) {
              onSubmitSnackBar({
                isOpen: true,
                isSuccess: false,
                message: response.error?.message,
              });
            } else {
              onSubmitSnackBar({
                isOpen: true,
                isSuccess: true,
                message: "Profile details updated successfully!",
              });
              onClose();
            }
          } catch (error) {
            console.error(error);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          setFieldValue,
          ...rest
        }) => (
          <>
            <FormContent>
              <DataGridLoading isLoading={isUserDetailsLoading || isUserDetailsError}>
                <>
                  <$Grid container spacing={2.5} pt={2}>
                    <$Grid container item spacing={2}>
                      <$Grid item xs={6}>
                        <$TextField
                          fullWidth
                          id="firstName"
                          name="firstName"
                          label="First Name"
                          size="small"
                          required
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.firstName && Boolean(errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                          data-testid="firstName-textField"
                        />
                      </$Grid>

                      <$Grid item xs={6}>
                        <$TextField
                          fullWidth
                          id="lastName"
                          name="lastName"
                          label="Last Name"
                          size="small"
                          required
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.lastName && Boolean(errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                          data-testid="lastName-textField"
                        />
                      </$Grid>
                    </$Grid>

                    <$Grid container item spacing={2}>
                      <$Grid item xs={12}>
                        <$TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Email"
                          size="small"
                          required
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={true}
                        />
                      </$Grid>
                    </$Grid>

                    <$Grid container item spacing={2}>
                      <$Grid item xs={6}>
                        <$PhoneNumberInput
                          fullWidth
                          size="small"
                          id="mobilePhone"
                          name="mobilePhone"
                          label="Mobile"
                          value={values.mobilePhone}
                          onChange={(val: any) => setFieldValue("mobilePhone", val)}
                          onBlur={handleBlur}
                          error={touched.mobilePhone && Boolean(errors.mobilePhone)}
                          helperText={touched.mobilePhone && errors.mobilePhone}
                          required={false}
                          data-testid="mobilePhone-input"
                        />
                      </$Grid>

                      <$Grid item xs={6}>
                        <$PhoneNumberInput
                          fullWidth
                          size="small"
                          id="workPhone"
                          name="workPhone"
                          label="Work"
                          value={values.workPhone}
                          onChange={(val: any) => setFieldValue("workPhone", val)}
                          onBlur={handleBlur}
                          error={touched.workPhone && Boolean(errors.workPhone)}
                          helperText={touched.workPhone && errors.workPhone}
                          required={false}
                          data-testid="workPhone-input"
                        />
                      </$Grid>
                    </$Grid>

                    <$Grid container item spacing={2}>
                      <$Grid item xs={12}>
                        <$TextField
                          fullWidth
                          id="jobTitle"
                          name="jobTitle"
                          label="Job Title"
                          size="small"
                          value={values.jobTitle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.jobTitle && Boolean(errors.jobTitle)}
                          helperText={touched.jobTitle && errors.jobTitle}
                          data-testid="jobTitle-textField"
                        />
                      </$Grid>
                    </$Grid>
                  </$Grid>
                </>
              </DataGridLoading>
            </FormContent>

            <FormFooter
              drawerStatusEvent={(event) => {
                if (event === DrawerEventType.Save) {
                  handleSubmit();
                } else if (event === DrawerEventType.Cancel) {
                  onClose();
                }
              }}
              disableSaveBtn={!rest.isValid || isUserDetailsLoading}
              isLoading={isSubmitting}
              areButtonsDisabled={isSubmitting}
            />
          </>
        )}
      </Formik>
    </>
  );
};
