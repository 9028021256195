import { UniCodeValidator } from "Common/Validators";
import * as Yup from "yup";

Yup.addMethod(Yup.string, "validUnicode", function () {
  return this.matches(UniCodeValidator, "Invalid Unicode characters.");
});

export const CreateBugValidatorSchema = Yup.object().shape({
  title: Yup.string().trim().validUnicode().required("Title is required."),
  description: Yup.string()
    .trim()
    .validUnicode()
    .required("Description is required.")
    .min(100, "Description must be at least 100 characters."),
});
