import { $Avatar, $Stack, $Typography } from "Common/Components";
import { getColorById } from "Common/Functions";
import { useTheme } from "@mui/material/styles";

export interface IApplicationAvatar {
  applicationId: string;
  name?: string;
  description?: string;
  onClick?: any;
  width?: string;
  height?: string;
  onlyAvatar?: boolean;
  icon?: JSX.Element;
  logoLink?: string | undefined;
}

export const ApplicationAvatar: React.FC<IApplicationAvatar> = (props) => {
  const theme = useTheme();
  const { name, description, applicationId, width = 42, height = 42, onlyAvatar, logoLink } = props;

  return (
    <>
      <$Stack
        spacing={onlyAvatar ? 0 : 2}
        direction="row"
        alignItems="center"
        data-testid="application-avatar"
      >
        <$Avatar
          sx={{
            width: { width },
            height: { height },
            bgcolor: getColorById(applicationId),
            color: "#fff",
            borderRadius: "3px",
          }}
          variant="rounded"
        >
          <img src={logoLink} alt="" />
        </$Avatar>
        {!onlyAvatar && (
          <$Stack>
            <$Typography variant="h5" color={theme.palette.primary.main}>
              {name}
            </$Typography>
            <$Typography variant="caption" color={theme.palette.grey[500]}>
              {description}
            </$Typography>
          </$Stack>
        )}
      </$Stack>
    </>
  );
};
