import { IAuthorization } from "Common/Interfaces";
import { baseAPI } from "Store";

const controller = "authorization";
export const authorizationAPI = baseAPI.injectEndpoints({
  endpoints: (build) => ({
    getAuthorization: build.query<IAuthorization, {}>({
      query: (query) => `${controller}?ModuleName=Ami.LandingPage`,
      transformResponse: (response: IAuthorization, meta, arg) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAuthorizationQuery } = authorizationAPI;
