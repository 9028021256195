export * from "./Authorization";
export * from "./Routes";
export * from "./AppDefaults";
export * from "./DrawerEventType";
export * from "./DialogEvent";
export * from "./EntityType";
export * from "./Authorization";
export * from "./Role";
export * from "./Common";
export * from "./ConfirmationDialogTypes";

export const HEADER_HEIGHT = "48px";
export const SIDEBAR_WIDTH = 80;
export const COLLAPSED_SIDEBAR_WIDTH = 0;
export const drawerWidth = 260;

export const config = {
  defaultPath: "",

  i18n: "en",
  miniDrawer: false,
  container: true,
  mode: "light",
  presetColor: "default",
  themeDirection: "ltr",
};
