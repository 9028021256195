import { IFeatureRoute } from "Common/Interfaces/Application";
import { Dashboard } from "Containers/Dashboard";

export const FEATURE_ROUTES: IFeatureRoute[] = [
  {
    path: "",
    element: <Dashboard />,
    children: [],
    featureFlag: "FEATURE_FLAGS.DASHBOARD",
  },
];
