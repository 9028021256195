import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import React from "react";

export const LoginPage: React.FC = () => {
  const { instance } = useMsal();

  useEffect(() => {
    const handleLoginEvent = async () => {
      try {
        // Only initiate login process if there is no active interaction.
        if (!instance.getActiveAccount() && !sessionStorage.getItem("msal.interaction.status")) {
          await instance.loginRedirect(loginRequest);
        }
      } catch (error) {
        console.log("Login Error", error);
      }
    };

    handleLoginEvent();
    // Dependency array is empty to ensure this effect runs only once on component mount.
  }, [instance]);

  return <React.Fragment></React.Fragment>;
};