export const checkIfAllowedFileType = (file: File, allowedFileTypes: Array<string>): boolean => {
    const fileNameSplitted = file?.name.split(".");
  
    if (fileNameSplitted.length <= 1) {
      return false;
    } else if (
      fileNameSplitted?.length > 1 &&
      allowedFileTypes.includes(fileNameSplitted[fileNameSplitted.length - 1]?.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };
  