import { IUIElementState, IUser, } from "Common/Interfaces";

export const UIElementStateDefault: IUIElementState<IUser> = {
  element: null,
  cellDetails: null,
  openElement: null,
  isSuccess: false,
  message: "",
  dataRow: null,
};
