import { APP_DEFAULTS } from "Common/Constants";
import { IDivisionListRequest } from "Common/Interfaces";

export const DEFAULT_DIVISION_SEARCH_QUERY: IDivisionListRequest = {
  limit: APP_DEFAULTS.PAGINATION_SIZE,
  offset: 0,
  orderBy: "Name",
  search: "",
  sortBy: "1",
  applications: "",
  isDeleted: false,
};
