import { $Box, $Skeleton } from "Common/Components";

export interface IDataGridLoading {
  isLoading: boolean;
  children: JSX.Element;
}

export const DataGridLoading: React.FC<IDataGridLoading> = (props) => {
  const { isLoading, children } = props;
  if (isLoading) {
    return (
      <$Box>
        <$Skeleton animation="wave" width="10%" />
        <$Skeleton animation="wave" width="40%" />
        <$Skeleton animation="wave" width="60%" />
      </$Box>
    );
  }
  return children;
};
