import { MoreVert } from "@mui/icons-material";
import {
  $Avatar,
  $Grid,
  $IconButton,
  $ListItemText,
  $Stack,
  $Typography,
  ApplicationAvatar,
  ContextMenu,
  DataGridLoading,
  FormContent,
  FormFooter,
  MenuItems,
} from "Common/Components";
import { DrawerEventType } from "Common/Constants";
import { stringAvatar } from "Common/Functions";
import { LABEL_VALUES } from "./Constants";
import { useViewProfile } from "./Hooks";
import { DetailItem, LabelBox } from "./Components";
import { getContextMenuItems } from "./Functions";
import { UIElement } from "Common/Interfaces";

interface IViewProfile {
  onClose: () => void;
  handleEditProfileButton: () => void;
}

export const ViewProfile: React.FC<IViewProfile> = (props) => {
  const { onClose, handleEditProfileButton } = props;

  const {
    uiElementState,
    setUiElementState,
    handleContextMenuButton,
    userDetails,
    isUserDetailsLoading,
    isUserDetailsError,
  } = useViewProfile();

  const handleContextMenuItem = (menuItem: MenuItems) => {
    switch (menuItem) {
      case MenuItems.UPDATE_INFO:
        handleEditProfileButton();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FormContent>
        <DataGridLoading isLoading={isUserDetailsLoading || isUserDetailsError}>
          <>
            <$Stack direction="row" justifyContent="space-between" alignItems="center">
              <$Stack direction="row" spacing={2} alignItems="center">
                <$Avatar
                  sx={(theme) => ({
                    width: 76,
                    height: 76,
                    fontSize: "24px",
                    fontWeight: theme.typography.fontWeightRegular,
                    bgcolor: theme.colors.primary.main,
                  })}
                  {...stringAvatar(userDetails?.displayName || "")}
                />
                <$ListItemText
                  sx={(theme) => ({
                    ".MuiListItemText-primary": {
                      fontSize: "1.2rem",
                    },
                  })}
                  primary={userDetails?.displayName ?? ""}
                  secondary={userDetails?.email ?? ""}
                />
              </$Stack>
              <$IconButton
                size="small"
                onClick={(event) => handleContextMenuButton(event)}
                sx={(theme) => ({
                  background: Boolean(uiElementState.openElement === UIElement.ContextMenu)
                    ? theme.palette.grey[200]
                    : "none",
                })}
              >
                <MoreVert fontSize="small" />
              </$IconButton>
            </$Stack>

            <$Stack pt={3} direction="row" spacing={2} alignItems="flex-start" justifyContent="space-between">
              <LabelBox primary={LABEL_VALUES.ENTITY_TYPE} secondary={userDetails?.entity?.typeName ?? ""} />
              <LabelBox primary={LABEL_VALUES.ENTITY_NAME} secondary={userDetails?.entity?.displayName ?? ""} />
              <LabelBox primary={LABEL_VALUES.ROLE} secondary={userDetails?.role?.displayName ?? ""} />
            </$Stack>

            <$Grid pt={3} container>
              <$Grid item xs={12}>
                <$Typography variant="h4" fontWeight="fontWeightMedium">
                  Account Information
                </$Typography>
              </$Grid>
            </$Grid>
            <$Stack pt={2} spacing={2}>
              <$Stack direction="row" spacing={4}>
                <DetailItem primary={LABEL_VALUES.FIRST_NAME} secondary={userDetails?.firstName ?? ""} />
                <DetailItem primary={LABEL_VALUES.LAST_NAME} secondary={userDetails?.lastName ?? ""} />
              </$Stack>
              {userDetails?.mobile && (
                <$Stack direction="row" spacing={4}>
                  <DetailItem primary={LABEL_VALUES.MOBILE_PHONE} secondary={userDetails.mobile ?? ""} />
                </$Stack>
              )}
              {userDetails?.work && (
                <$Stack direction="row" spacing={4}>
                  <DetailItem primary={LABEL_VALUES.WORK_PHONE} secondary={userDetails.work ?? ""} />
                </$Stack>
              )}
              {userDetails?.jobTitle && (
                <$Stack direction="row" spacing={4}>
                  <DetailItem primary={LABEL_VALUES.JOB_TITLE} secondary={userDetails.jobTitle ?? ""} />
                </$Stack>
              )}
            </$Stack>

            <$Grid pt={6} container>
              <$Grid item xs={12}>
                <$Typography variant="h4" fontWeight="fontWeightMedium">
                  My Applications
                </$Typography>
              </$Grid>
            </$Grid>
            <$Stack pt={2} spacing={2}>
              {userDetails?.applications &&
                userDetails.applications.map(({ id, displayName, description, logoURL }) => {
                  return (
                    <ApplicationAvatar
                      key={id}
                      applicationId={id.toString()}
                      name={displayName}
                      description={description}
                      logoLink={logoURL}
                    />
                  );
                })}
            </$Stack>
          </>
        </DataGridLoading>
      </FormContent>

      <FormFooter
        isHideCancelButton={true}
        saveBtnText="Close"
        drawerStatusEvent={(event) => {
          if (event === DrawerEventType.Save) {
            onClose();
          }
        }}
      />
      <ContextMenu
        anchorEl={Boolean(uiElementState.openElement === UIElement.ContextMenu) ? uiElementState.element : null}
        onCloseEvent={(reason) => {
          if (reason) {
            setUiElementState({ ...uiElementState, element: null, openElement: null });
          }
        }}
        menuItems={getContextMenuItems(handleContextMenuItem)}
        hideMoreVert={true}
        moreButtonClickEvent={(event: any) => {}}
      />
    </>
  );
};
