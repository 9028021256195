import { useTheme } from '@mui/material/styles';

const UnderConstructionImage = () => {
    const theme = useTheme();

    return (

        <>

            <svg width="141.25" height="103.65" viewBox="0 0 141.25 103.65" fill="none" xmlns="http://www.w3.org/2000/svg">

                <linearGradient id="linear-gradient" x1="70.62" y1="103.65" x2="70.62" y2="33.03" gradientUnits="userSpaceOnUse">
                    <stop offset=".15" stopColor={theme.palette.grey[100]} stopOpacity=".3" />
                    <stop offset=".38" stopColor="#f3f8fc" stopOpacity=".3" />
                    <stop offset=".59" stopColor="#cee4f3" stopOpacity=".3" />
                    <stop offset=".79" stopColor="#90c4e5" stopOpacity=".3" />
                    <stop offset=".99" stopColor="#3b96d1" stopOpacity=".3" />
                    <stop offset="1" stopColor="#3593d0" stopOpacity=".3" />
                </linearGradient>

                <path fill="url(#linear-gradient)" d="M0,83.3c0-39,31.6-70.6,70.6-70.6s70.6,31.6,70.6,70.6H0" />

                <path fill={theme.palette.primary.main} d="M27.6,10.4c-1,0-2,0.4-2.7,1.1c-0.7,0.7-1.1,1.7-1.1,2.7v11.3h-3.7c-1,0-2,0.4-2.7,1.1
	c-0.7,0.7-1.1,1.7-1.1,2.7v22.5c0,1,0.4,2,1.1,2.7c0.7,0.7,1.7,1.1,2.7,1.1h7.3L16.5,88.2c-0.3,1-0.3,2,0.1,2.9
	c0.4,0.9,1.2,1.6,2.2,1.9c1,0.3,2,0.2,2.9-0.2c0.9-0.5,1.6-1.3,1.9-2.2l7.7-23.2l7.7,23.2c0.4,1.3,1.5,2.2,2.8,2.5
	c1.3,0.3,2.7-0.2,3.6-1.2c0.9-1,1.2-2.4,0.8-3.7L35.3,55.5h74.8L99.3,88.2c-0.4,1.3-0.1,2.7,0.8,3.7c0.9,1,2.3,1.5,3.6,1.2
	c1.3-0.3,2.4-1.2,2.8-2.5l7.7-23.2l7.7,23.2c0.4,1.3,1.5,2.2,2.8,2.5c1.3,0.3,2.7-0.2,3.6-1.2c0.9-1,1.2-2.4,0.8-3.7l-10.9-32.7h7.3
	c1,0,2-0.4,2.7-1.1c0.7-0.7,1.1-1.7,1.1-2.7V29.3c0-1-0.4-2-1.1-2.7c-0.7-0.7-1.7-1.1-2.7-1.1h-3.7V14.1c0-1-0.4-2-1.1-2.7
	c-0.7-0.7-1.7-1.1-2.7-1.1h-15.1c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.7-1.1,2.6v11.3H80.7c-0.2,0-0.4,0-0.6,0c-0.1,0-0.3,0-0.4,0
	H58c-0.2,0-0.4,0-0.6,0c-0.1,0-0.3,0-0.4,0H46.4V14.1c0-1-0.4-1.9-1.1-2.6c-0.7-0.7-1.7-1.1-2.6-1.1L27.6,10.4z M31.4,17.9h7.5v7.5
	h-7.5V17.9z M106.6,17.9h7.5v7.5h-7.5L106.6,17.9z M23.8,33h8.5l5,15H23.8L23.8,33z M62.8,33h14.8l5,15H67.8L62.8,33z M108.1,33
	h13.6v15h-8.6L108.1,33z"/>
            </svg>


        </>
    );
};

export default UnderConstructionImage;
