import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";

export class SignalRService {
  private connection: HubConnection;
  private readonly hubUrl: string = process.env.REACT_APP_COM_HUB_URL || "";

  constructor() {
    this.connection = new HubConnectionBuilder()
      .withUrl(this.hubUrl)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect() // This enables automatic reconnect
      .build();
  }

  public async startConnection(): Promise<void> {
    if (this.connection.state === HubConnectionState.Disconnected) {
      try {
        await this.connection
          .start()
          .then(() => console.log("Connection established"))
          .catch((err) => {
            console.error("Error establishing connection:", err);
            // Additional logic based on the error can be placed here
          });
      } catch (error) {
        console.error("Error establishing SignalR connection:", error);
      }
    }
  }

  public async stopConnection(): Promise<void> {
    if (this.connection.state !== HubConnectionState.Disconnected) {
      await this.connection.stop();
      console.log("SignalR connection stopped.");
    }
  }

  public sendLogoutRequest(accountId: string): Promise<void> {
    return this.connection.invoke("BroadcastLogout", accountId)
      .then(() => console.log("Logout request sent for accountId:", accountId))
      .catch((error) => {
        console.error("Error sending logout request:", error);
        throw error; // Rethrow the error to ensure it can be caught by the caller
      });
  }
  

  public setLogoutResponseCallback(
    callback: (accountId: string) => void
  ): void {
    this.connection.off("UserLoggedOut"); // Remove existing listeners to avoid duplicates
    this.connection.on("UserLoggedOut", (accountId: string) => {
      callback(accountId);
    });
  }

    // New method to expose the connection state
    public isConnected(): boolean {
      return this.connection.state === HubConnectionState.Connected;
    }
}
