import { IconButton, List, useTheme } from "@mui/material";
import { SettingOutlined } from "Icons/Icons";
import { useHeaderPopup } from "../Hooks";
import { HeaderListItem, HeaderPopup } from "../Components";
import { $Snackbar, DeleteConfirmationContent, FormDrawer } from "Common/Components";
import { CreateBugForm } from "BugTracker/Containers";
import { UIElement } from "Common/Interfaces";
import { CONFIRMATION_DIALOG_TYPES, DialogEvent, DrawerEventType } from "Common/Constants";
import { useState } from "react";
import { IBugUIElementState } from "BugTracker/Containers/BugTracker/Interfaces";
import { IBug } from "BugTracker/Common/Interfaces";
import { DEFAULT_BUG_UI_ELEMENT_STATE } from "BugTracker/Containers/BugTracker/Constants";
import { DeleteConfirmation } from "Common/Components/DeleteConfirmation";

export const Settings = () => {
  const { anchorRef, open, handleToggle, handleClose } = useHeaderPopup();
  const theme = useTheme();
  const [bugDrawerOpen, setBugDrawerOpen] = useState(false);
  const [uiElementState, setUiElementState] = useState<IBugUIElementState<IBug>>(DEFAULT_BUG_UI_ELEMENT_STATE);

  const closeDrawerConfirmation = () => {
    setUiElementState({
      element: null,
      cellDetails: null,
      openElement: UIElement.CancelConfirmation,
      isSuccess: false,
      message: "",
      dataRow: null,
    });
  };

  const closeUIElement = () => {
    setUiElementState(DEFAULT_BUG_UI_ELEMENT_STATE);
  };

  const snackbarCloseEventHandler = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === "clickaway") {
      return;
    }
    closeUIElement();
  };

  const cancelDialogEventHandler = (event: DialogEvent) => {
    switch (event) {
      case DialogEvent.Confirm:
        setBugDrawerOpen(false);
        setUiElementState((prev) => ({ ...prev, openElement: null }));
        break;
      case DialogEvent.Cancel:
        setUiElementState((prev) => ({ ...prev, openElement: null }));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <IconButton
        onClick={handleToggle}
        ref={anchorRef}
        size="small"
        sx={(theme) => ({
          color: theme.palette.common.white,
          padding: 1.3,
        })}
      >
        <SettingOutlined sx={{ fontSize: "1.2rem" }} />
      </IconButton>

      <HeaderPopup anchorEl={anchorRef.current} minWidth={100} open={open} handleClose={handleClose}>
        <List
          component="nav"
          sx={{
            px: 1,
            py: 1,
            "& .MuiListItemIcon-root": {
              color: theme.palette.primary.main,
            },
          }}
        >
          <HeaderListItem
            primaryText="Report a Bug"
            onClick={() => {
              setBugDrawerOpen(true);
              handleToggle();
            }}
          />
        </List>
      </HeaderPopup>

      <FormDrawer
        drawerStatusEvent={(event) => {
          if (event === DrawerEventType.Close) {
            setUiElementState({
              element: null,
              cellDetails: null,
              openElement: UIElement.CancelConfirmation,
              isSuccess: false,
              message: "",
              dataRow: null,
            });
          }
        }}
        drawerTitle={"Report a Bug"}
        isOpen={bugDrawerOpen}
        width="40%"
        className="print-only-area"
      >
        <CreateBugForm
          onClose={closeDrawerConfirmation}
          drawerCloseEvent={() => setBugDrawerOpen(false)}
          onSubmitSnackbar={({ isOpen, isSuccess, message }) => {
            setUiElementState({
              cellDetails: null,
              element: null,
              isSuccess: isSuccess ?? false,
              message,
              openElement: UIElement.SnackBar,
              dataRow: null,
            });
          }}
        />
      </FormDrawer>

      <$Snackbar
        open={uiElementState.openElement === UIElement.SnackBar}
        onClose={snackbarCloseEventHandler}
        isSuccess={uiElementState.isSuccess}
        message={uiElementState.message}
      />

      <DeleteConfirmation
        isOpen={uiElementState.openElement === UIElement.CancelConfirmation}
        dialogEvent={cancelDialogEventHandler}
        isSubmitting={false}
        confirmButtonText={"Yes"}
        cancelButtonText={"No"}
        dialogTitle={`Confirm Cancellation !`}
        dialogBody={
          <DeleteConfirmationContent
            value={""}
            title={"Changes you made so far will not be saved. Are you sure you want to cancel"}
          />
        }
        tooltipTitle={{
          cancelBtn: "Click to cancel the action and return to the previous state",
          actionBtn: "Click to confirm and proceed with the action",
        }}
        action={CONFIRMATION_DIALOG_TYPES.WARNING}
      />
    </>
  );
};
