import * as Yup from "yup";

export const UniCodeValidator = /^[\p{L}\p{N}\p{P}\p{S}\p{Z}\n]*$/u;
export const PhoneNumberValidator = /^(?:\+1[0-9]{10}|(?!\+1)\+?[0-9\s.-]*[0-9]{7,15})$/;

Yup.addMethod(Yup.string, "validUnicode", function () {
  return this.matches(UniCodeValidator, "COMMON.INVALID_UNICODE_CHARACTERS");
});

declare module "yup" {
  interface StringSchema {
    validUnicode(message?: string): this;
  }
}
