import { useTheme } from "@mui/material";

export const AppLoading = () => {
  const theme = useTheme();
  return (
    <>
      <div className="loding-container">
        <div className="loading_outer">
          <div className="loader-svg">
            <div className="mid">
              <svg width="100px" height="100px" viewBox="0 0 100 100">
                <polygon
                  className="svg1"
                  stroke="#fff"
                  strokeWidth="1"
                  fill={theme.colors.primary.main}
                  points="64.08 33.72 64.08 67.32 1.51 67.32 64.08 33.72"
                />

                <polygon
                  className="svg2"
                  stroke="#FFF"
                  strokeWidth="1"
                  fill={"#E32A3F"}
                  points="98.49 33.72 98.49 67.32 35.92 67.32 98.49 33.72"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
