import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

interface IHeaderListItem {
  onClick: () => void;
  icon?: JSX.Element;
  primaryText: string;
}

export const HeaderListItem = (props: IHeaderListItem) => {
  const { onClick, icon, primaryText } = props;

  return (
    <ListItemButton onClick={onClick}>
      {icon && (
        <ListItemIcon sx={{  "& .MuiSvgIcon-root": { fontSize: "1.5rem" } }}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText primary={primaryText} sx={{
        "&.MuiListItemText-root": {
          marginLeft: !icon ? 0 : "10px",
        },
      }} />
    </ListItemButton>
  );
};
