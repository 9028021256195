import { useIsAuthenticated } from "@azure/msal-react";
import { LANDING_PAGE_PERMISSION } from "Common/Constants";
import { useGetAuthorizationQuery } from "Store/Services/Authorization";

export const useIsActionAuthorized = (action?: LANDING_PAGE_PERMISSION) => {
  const isAuthenticated = useIsAuthenticated();
  const { data: authDetails } = useGetAuthorizationQuery({}, { skip: !isAuthenticated });
  const isActionAuthenticated = action && authDetails?.permissions.some((authItem) => authItem === action);
  return { isActionAuthenticated, authDetails };
};
