import { $Box } from "Common/Components";
import React from "react";

interface IMessageLayout {
  children: React.ReactNode;
}

export const MessageLayout: React.FC<IMessageLayout> = (props) => {
  const { children } = props;

  return (
    <$Box sx={{ display: "flex", height: "100vh", width: "100%", alignItems: "center", justifyContent: "center" }}>
      {children}
    </$Box>
  );
};
