import React, { useState } from "react";
import { DefaultSettingsT, SettingsT } from "./Settings";
import Carousel from "react-material-ui-carousel";
import "./MainSlider.scss";

import { Card, CardContent, CardMedia, Typography, Grid, Button } from "@mui/material";
import { $Stack, $Typography } from "Common/Components";
import { useMatchDownMd } from "Common/Hooks";

export const MainSlider = () => {
  const [settings] = useState<SettingsT>(DefaultSettingsT);
  const { matchDownMd } = useMatchDownMd();
  return (
    <div>
      <Carousel
        className="Example"
        {...settings}
        indicators={matchDownMd ? true : false}
        navButtonsAlwaysInvisible={matchDownMd ? true : false}
      >
        {items.map((item, index) => {
          return <Banner item={item} key={index} contentPosition={item.contentPosition} />;
        })}
      </Carousel>
    </div>
  );
};

type Item = {
  Name: string;
  Caption: string;
  Summary: string | JSX.Element;
  href: string;
  contentPosition: "left" | "right" | "middle";
  Items: { Name: string; Image: string }[];
};

interface BannerProps {
  item: Item;
  contentPosition: "left" | "right" | "middle";
  length?: number;
}

const Banner = (props: BannerProps) => {
  const contentPosition = props.contentPosition ? props.contentPosition : "left";
  const totalItems: number = props.length ? props.length : 2;
  const mediaLength = totalItems - 1;
  let items = [];
  const content = (
    <Grid item xs={12} md={7} key="content">
      <CardContent
        className="Content"
        sx={(theme) => ({
          height: "100%",
          minHeight: "416px",
          width: "100%",
          cursor: "pointer",
          transition: "300ms",
          padding: "30px",
          position: "relative",
          overflow: "hidden",
          color: "#09344f",
          backgroundColor: "#fff",
          [theme.breakpoints.down("md")]: {
            height: "400px",
            padding: "20px",
            paddingX: "30px",
          },
          [theme.breakpoints.down("sm")]: {
            height: "500px",
            maxHeight: "500px",
            padding: "20px",
            paddingX: "30px",
          },
        })}
      >
        <$Stack
          justifyContent="space-between"
          alignItems={"start"}
          sx={(theme) => ({
            height: "90%",
            [theme.breakpoints.down("sm")]: {
              height: "100%",
            },
          })}
        >
          <$Stack alignItems={"stretch"}>
            <Typography className="Title">{props.item.Name}</Typography>
            <Typography
              className="Caption"
              sx={(theme) => ({
                fontSize: "1.5rem",
                [theme.breakpoints.down("md")]: {
                  fontSize: "1rem",
                },
              })}
            >
              {props.item.Caption}
            </Typography>
            <$Typography
              className="Caption"
              sx={(theme) => ({
                fontSize: "1rem",
                [theme.breakpoints.down("md")]: {
                  fontSize: "0.9rem",
                },
              })}
            >
              {props.item.Summary}
            </$Typography>
          </$Stack>
          <Button
            variant="outlined"
            className="ViewButton"
            sx={(theme) => ({
              marginTop: "40px",
              color: "#09344f",
              border: "1px solid #09344f",
              textTransform: "capitalize",
              [theme.breakpoints.down("md")]: {
                border: "1px solid #09344f",
              },
            })}
            component="a"
            href={props.item.href}
            target="_blank"
            rel="noreferrer"
          >
            View Now
          </Button>
        </$Stack>
      </CardContent>
    </Grid>
  );

  for (let i = 0; i < mediaLength; i++) {
    const item = props.item.Items[i];

    const media = (
      <Grid item xs={12} md={5} key={item.Name}>
        <CardMedia
          sx={(theme) => ({
            height: "416px",
            width: "100%",
            position: "relative",
            overflow: "hidden",
            [theme.breakpoints.down("md")]: {
              height: "300px",
              objectFit: "cover",
            },
            [theme.breakpoints.down("sm")]: {
              height: "250px",
              objectFit: "contain",
            },
          })}
          image={item.Image}
          title={item.Name}
        ></CardMedia>
      </Grid>
    );

    items.push(media);
  }

  if (contentPosition === "left") {
    items.unshift(content);
  } else if (contentPosition === "right") {
    items.push(content);
  } else if (contentPosition === "middle") {
    items.splice(items.length / 2, 0, content);
  }

  return (
    <Card
      raised
      className="Banner"
      sx={{
        height: "100%",
        minHeight: "416px",
      }}
    >
      <Grid container spacing={0} className="BannerGrid">
        {items}
      </Grid>
    </Card>
  );
};

const items: Item[] = [
  {
    Name: "TAYLOR",
    Caption: "WHY GOOD THINGS START WITH TAYLOR",
    contentPosition: "right",
    href: "https://www.taylor-company.com/about/why-taylor/",
    Summary: (
      <>
        <$Typography
          className="Caption"
          sx={(theme) => ({
            fontSize: "1rem",
            [theme.breakpoints.down("md")]: {
              fontSize: "0.9rem",
            },
          })}
        >
          Eye-popping desserts. Menus that sizzle. And back-of-house operations that run like clockwork. This is where all
          good things begin!
        </$Typography>
        <$Typography
          className="Caption"
          sx={(theme) => ({
            fontSize: "1rem",
            [theme.breakpoints.down("md")]: {
              fontSize: "0.9rem",
            },
          })}
        >
          Count on us for the tools to work smarter, local support that truly understands your business, and service to keep
          you going strong. It’s a new day in foodservice—and we’re here to help you make the most of it.{" "}
        </$Typography>
      </>
    ),
    Items: [
      {
        Name: "TAYLOR",
        Image: `${process.env.REACT_APP_BLOB_STORAGE_URL}landing-page-images/Taylor.jpg`,
      },
    ],
  },
  {
    Name: "PITCO",
    Caption: "FRYERS THAT LAST",
    contentPosition: "right",
    href: "https://www.pitco.com/why-pitco/",
    Summary:
      "One of our favorite pastimes is going into a restaurant and seeing a Pitco Frialator that’s several decades old. And there’s a reason for it. Pitco uses state-of-the-art manufacturing techniques like today’s laser cutting, robotic welding, and pressure-blasted tanks so every corner, every seam, is as close to perfect as possible.",
    Items: [
      {
        Name: "PITCO",
        Image: `${process.env.REACT_APP_BLOB_STORAGE_URL}landing-page-images/Pitco.png`,
      },
    ],
  },
  {
    Name: "TURBOCHEF",
    Caption: "Ventless High-speed Conveyors",
    contentPosition: "right",
    href: "https://turbochef.com/product-category/conveyors/",
    Summary:
      "TurboChef’s unprecedented High-h air impingement and available catalyst technology heats food faster than any other conveyor on the market.",
    Items: [
      {
        Name: "TURBOCHEF",
        Image: `${process.env.REACT_APP_BLOB_STORAGE_URL}landing-page-images/TurboChef.jpg`,
      },
    ],
  },
  {
    Name: "MIDDLEBY MARSHALL",
    Caption: "World Leaders of Conveyor Ovens",
    contentPosition: "right",
    href: "https://www.middlebymarshall.com/history/",
    Summary:
      "Since 1888, Middleby Marshall has been world leaders in conveyor cooking. There is a Middleby Marshall conveyor oven to fit every culinary innovation, quality of speed and capacity need. With our patented Energy Management System and CTX technology, a Middleby Marshall oven saves operators on energy costs from the very first day of use, while delivering a perfect, consistent product that diners come back for time and time again. The kitchen of today is increasingly becoming one of innovation, where equipment automates much of the cooking process.",
    Items: [
      {
        Name: "Middleby Marshall ",
        Image: `${process.env.REACT_APP_BLOB_STORAGE_URL}landing-page-images/MiddleByMarshell.jpg`,
      },
    ],
  },
  {
    Name: "BLODGETT",
    Caption: "Built Stronger.  Lasts Longer",
    contentPosition: "right",
    href: "https://www.blodgett.com/why-blodgett/",
    Summary: (
      <>
        <$Typography
          className="Caption"
          sx={(theme) => ({
            fontSize: "1rem",
            [theme.breakpoints.down("md")]: {
              fontSize: "0.9rem",
            },
          })}
        >
          Blodgett ovens are work-horse cooking equipment built with quality and engineered to withstand years of operation.
        </$Typography>
        <$Typography
          className="Caption"
          sx={(theme) => ({
            fontSize: "1rem",
            [theme.breakpoints.down("md")]: {
              fontSize: "0.9rem",
            },
          })}
        >
          Superior Durability – fully welded, extruded angle iron frame keeps oven square and true Better Lifetime
          Performance – Hybrid insulation with a layer of Superwool® HT for improved insulative qualities.
        </$Typography>
        <$Typography
          className="Caption"
          sx={(theme) => ({
            fontSize: "1rem",
            [theme.breakpoints.down("md")]: {
              fontSize: "0.9rem",
            },
          })}
        >
          A Joy to Use – Superior turnbuckle assembly keeps door operation hassle-free Fewer Headaches – double porcelainized
          interior surface prevents hidden rust and pitting.
        </$Typography>
      </>
    ),
    Items: [
      {
        Name: "Blodgett",
        Image: `${process.env.REACT_APP_BLOB_STORAGE_URL}landing-page-images/Blodgett.jpg`,
      },
    ],
  },
];
