import { InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { FileState } from "BugTracker/Common/Constants/FileUpload";
import { IBug } from "BugTracker/Common/Interfaces";
import {
  addUploadReadyFile,
  cancelUploadSingleFile,
  generateFileNumber,
  getFileDisplayValue,
  setFileState,
  setProgress,
} from "BugTracker/Store/FileSlice";
import { RootState } from "Store/Store";
import { amiAdminApiTokenRequest as amiApiTokenRequest, loginRequest, msalConfig } from "authConfig";
import axios, { AxiosRequestConfig } from "axios";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

export const uploadFileStraight =
  (bug: IBug, filesArray: File[], apiController: string = "bugtracker"): ThunkAction<void, RootState, unknown, Action> =>
  async (dispatch, getState) => {
    const { bugTrackerFile: fileBeforeUploadStart } = getState();
    const controller = new AbortController();
    const formData = new FormData();
    formData.append("title", bug.title?.trim());
    formData.append("description", bug.description?.trim());
    formData.append("application", "landingpage");
    formData.append("divisionCode", bug.divisionCode?.trim() ?? "");
    filesArray.forEach((file) => {
      formData.append("attachments", file, file.name);
      const fileNumber = generateFileNumber(fileBeforeUploadStart);

      dispatch(
        addUploadReadyFile({
          fileData: {
            file,
            controller,
            formData,
            fileNumber,
            progress: 0,
            fileState: FileState.Uploading,
            fileName: file.name,
            fileType: file.type,
            fileSizeInBytes: file.size,
            fileSizeDisplayValue: getFileDisplayValue(file.size),
          },
        })
      );
    });

    let token = "";
    const msalInstance = new PublicClientApplication(msalConfig);
    try {
      token = (await msalInstance.acquireTokenSilent(amiApiTokenRequest)).accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        token = (await msalInstance.acquireTokenPopup(amiApiTokenRequest)).accessToken;
      }
    }
    if (token === "") {
      msalInstance.loginRedirect(loginRequest).catch((error) => console.log("loginError", error));
    }

    const { bugTrackerFile: file } = getState();
    const { controller: updatedController, formData: updatedFormData } = file[0] ?? {};

    const config: AxiosRequestConfig<any> = {
      signal: updatedController?.signal,
      headers: {
        "content-type": "multipart/form-data",
        authorization: `Bearer ${token}`,
        isSingleFileUpload: "true",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_SUBSCRIPTION_KEY,
      },
    };
    const url = `${process.env.REACT_APP_API_BASE_URL}${apiController}`;

    try {
      const response = await axios.post(url, updatedFormData, config);
      console.log(response);
      if (response.status === 201) {
        file.forEach((file) => {
          dispatch(
            setFileState({ fileNumber: file.fileNumber, fileState: FileState.UploadCompleted, bugId: response?.data?.bugId })
          );
        });
      } else {
        file.forEach((file) => {
          dispatch(setFileState({ fileNumber: file.fileNumber, fileState: FileState.UploadFailed }));
        });
      }
    } catch (error: any) {
      console.log(error);
      file.forEach((file) => {
        dispatch(cancelUploadSingleFile(file.fileNumber));
      });
    }
  };
