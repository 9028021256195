import { EntityType, ROUTE_ENTITY_TYPE_NAMES, Role } from "Common/Constants";
import { IAuthMetadata } from "Common/Interfaces";

export /**
 *
 * Get the action button route
 * @param {(IAuthMetadata | undefined)} authMetadata api response data
 * @return {*}  {string} route for the admin center
 */
const getActionButtonRoute = (authMetadata: IAuthMetadata | undefined): string => {
  const baseURL = process.env.REACT_APP_ADMIN_CENTER_BASE_URL ?? "";

  switch (`${authMetadata?.entityType}-${authMetadata?.roleName}`) {
    case `${EntityType.Corporate}-${Role.ADMIN}`:
      return baseURL;

    case `${EntityType.Division}-${Role.ADMIN}`:
      return `${baseURL}${ROUTE_ENTITY_TYPE_NAMES.DIVISIONS}/${authMetadata?.entityId}`;

    case `${EntityType.Provider}-${Role.ADMIN}`:
      return `${baseURL}${ROUTE_ENTITY_TYPE_NAMES.PROVIDERS}/${authMetadata?.entityId}`;

    case `${EntityType.Customer}-${Role.ADMIN}`:
      return `${baseURL}${ROUTE_ENTITY_TYPE_NAMES.CUSTOMERS}/${authMetadata?.entityId}`;

    default:
      return baseURL;
  }
};
