import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVICE_TAG_TYPES } from "./Constants";
import { InteractionRequiredAuthError, BrowserAuthError, PublicClientApplication } from "@azure/msal-browser";
import { amiAdminApiTokenRequest as amiApiTokenRequest, msalConfig } from "authConfig";

export const documentBaseAPI = createApi({
  reducerPath: "documentReducer",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_DOCUMENT_API_BASE_URL,
    prepareHeaders: async (headers, { getState }) => {
      let token = "";
      const msalInstance = new PublicClientApplication(msalConfig);
      try {
        token = (await msalInstance.acquireTokenSilent(amiApiTokenRequest)).accessToken;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
          try {
            token = (await msalInstance.acquireTokenPopup(amiApiTokenRequest)).accessToken;
          } catch (error) {
            console.log("popup token acquire error: ", error);
          }
        } else if (error instanceof BrowserAuthError) {
          msalInstance
            .handleRedirectPromise()
            .then(async (tokenResponse) => {
              if (!tokenResponse) {
                const accounts = msalInstance.getAllAccounts();
                if (accounts.length === 0) {
                  await msalInstance.loginRedirect();
                }
              }
            })
            .catch((err) => {
              console.log("Redirect promise error:", err);
            });
        } else {
          console.log("silent token acquisition error:", error);
        }
      }
      headers.set("authorization", `Bearer ${token}`);
      headers.set("Strict-Transport-Security", "max-age=3600 ; includeSubDomains");
      headers.set("Ocp-Apim-Subscription-Key", process.env.REACT_APP_SUBSCRIPTION_KEY ?? "");

      headers.set("Accept", "*/*");
      headers.set("Accept-Encoding", "gzip, deflate, br");
      headers.set("Connection", "keep-alive");
      return headers;
    },
  }),
  tagTypes: Object.values(SERVICE_TAG_TYPES),
  endpoints: () => ({}),
  keepUnusedDataFor: 1,
});
