import { $Box, $Button, $Typography } from "./MUI";

import { useTheme } from "@mui/material/styles";
import UserInactiveImage from "../StateMessagesSvg/UserInactiveImage";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { msalConfig} from "authConfig";

interface IPageNotFound {
  headerMessage: string;
  detailMessage: string;
  goToHomeButton?: boolean;
}

export const PageNotFound: React.FC<IPageNotFound> = (props) => {
  const { headerMessage, detailMessage, goToHomeButton } = props;

  const theme = useTheme();
  const { instance } = useMsal();
  const navigate = useNavigate();

  const buttonClickEventhandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (goToHomeButton) {
      navigate("/");
    } else {
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        postLogoutRedirectUri:msalConfig.auth.postLogoutRedirectUri,
        idTokenHint: instance.getActiveAccount()?.idToken
      });
    }
  };

  return (
    <>
      <$Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <$Box>
          <UserInactiveImage />
        </$Box>
        <$Typography variant="h4" color="primary.dark" fontWeight={400}>
          {headerMessage}
        </$Typography>
        <$Typography variant="caption" color={theme.palette.text.secondary} textAlign="center">
          {detailMessage}
        </$Typography>
        <$Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ mt: 4, px: 4 }}
          onClick={(e: React.MouseEvent<HTMLElement>) => buttonClickEventhandler(e)}
        >
          {`${goToHomeButton ? "Back to Home" : "Back to Login"}`}
        </$Button>
      </$Box>
    </>
  );
};
