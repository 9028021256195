import { useRef, useState } from "react";

export const useHeaderPopup = () => {
  const anchorRef: any = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return {
    anchorRef,
    open,
    handleToggle,
    handleClose,
  };
};
